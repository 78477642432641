import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import Routes from 'app/routes';
import routes from 'app/routes/_routes';

import 'app/assets/styles/style.css';

const App = () => {
  return (
      <RecoilRoot>
        <BrowserRouter>
          <Routes routes={routes} />
        </BrowserRouter>
      </RecoilRoot>
  );
};

export default App;
