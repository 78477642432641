import React from "react";
import { Button, Menu } from "antd";
import axios from "axios";

const DownloadActions = () => {
  // const urlPrefix = process.env.NODE_ENV === 'production' ? '/public/templates' : '/templates';
  const urlPrefix = '/templates';

  const actual   = `${urlPrefix}/Actual_Upload_Template.xlsx`
  const forecast = `${urlPrefix}/Forecast_Upload_Template.xlsx`
  const budget   = `${urlPrefix}/Budget_Upload_Template.xlsx`

  const handleDownload = async (template: string, fileName: string) => {
    try {
      const response = await axios({
        url: template,
        method: 'GET',
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
      });

      const url = window.URL.createObjectURL(new Blob([response.data],
        { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      // @ts-ignore
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Menu className="border rounded-md">
      <Menu.Item key="download actuals template">
        <Button type="text" onClick={() => handleDownload(actual, "Actual_Upload_Template.xlsx")}>
          Actuals template
        </Button>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="download forecast template">
        <Button type="text" onClick={() => handleDownload(forecast, "Forecast_Upload_Template.xlsx")}>
          Forecast template
        </Button>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="download budget template">
        <Button type="text" onClick={() => handleDownload(budget, "Budget_Upload_Template.xlsx")}>
          Budget template
        </Button>
      </Menu.Item>
    </Menu>
  );
};

export default DownloadActions;
