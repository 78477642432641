import { AutoComplete, Table, Tag } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { HiOutlineSearch } from "react-icons/hi";
import { INotification, INotificationStatus, INotificationType } from "../../../../typings";
import { DAILY_DISPLAY_FORMAT } from "../../../../utils/helpers/constants";

type Props = {
  loading?: boolean;
  data?: INotification[];
}

const NotificationTable = ({ loading, data }:Props) => {

  const [dataSource, setDataSource] = useState(data);

  const getColor = (status: INotificationStatus) => {
    let color: string
    switch (status) {
      case 'CU':
        color = 'blue'
        break
      case 'PR':
        color = 'brown'
        break;
      case 'PA':
        color = 'green'
        break

      default: color = 'blue'
    }

    return color
  }

  const getTitle = (status: INotificationStatus) => {
    let title: string
    switch (status) {
      case 'CU':
        title = 'Current'
        break
      case 'PR':
        title = 'Processing'
        break;
      case 'PA':
        title = 'Success'
        break

      default: title = 'blue'
    }

    return title
  }

  const getType = (types: INotificationType) => {
    let type: string
    switch (types) {
      case 'FO':
        type = 'Forecast'
        break
      case 'AC':
        type = 'Actual'
        break;
      case 'BU':
        type = 'Budget'
        break

      default: type = 'AC'
    }

    return type
  }

  const columns = useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
        onHeaderCell: () => ({ className: 'bg-transparent' }),
      },
      {
        title: 'NAME',
        dataIndex: 'file_name',
        onHeaderCell: () => ({ className: 'bg-transparent' }),
      },
      {
        title: 'TYPE',
        dataIndex: 'upload_type',
        onHeaderCell: () => ({ className: 'bg-transparent' }),
        render: (type) => (
          <Tag key={type}>
            {getType(type)}
          </Tag>
        ),
      },
      {
        title: 'STATUS',
        dataIndex: 'status',
        onHeaderCell: () => ({ className: 'bg-transparent' }),
        render: (status) => (
          <Tag color={getColor(status)} key={status}>
            {getTitle(status)}
          </Tag>
        ),
      },
      {
        title: 'DATE',
        dataIndex: 'created_at',
        onHeaderCell: () => ({ className: 'bg-transparent' }),
        render: (created_at) => (<span>{moment(created_at).format(DAILY_DISPLAY_FORMAT)}</span>)
      },
    ],
    [loading, dataSource],
  );

  const options = useMemo(() => {
    return dataSource!
      .filter((datum) => datum.file_name)
      .map((source) => ({
        value: `${source.file_name} ${source.upload_type}`,
        label: `${source.file_name} ${source.upload_type}`,
      }));
  }, [dataSource]);

  const onSearch = (query: string) => {
    if (query) {
      const filtered = data!.filter((datum) => {
        const fullName = `${datum.file_name}`;

        return fullName
          .toLocaleLowerCase()
          .includes(query.toLocaleLowerCase());
      });

      setDataSource(filtered);
      return;
    }
    setDataSource(data);
  };

  useEffect(() => {
    setDataSource(data);
  }, [data]);


  return (
    <>
      <div className="mb-3 w-[200px] xs:w-full">
        <AutoComplete
          allowClear
          options={options}
          placeholder={
            <div className="flex justify-center items-center absolute">
              <HiOutlineSearch size="19.2px" />
              <span className="ml-2">
                Search for a user by first name or last name
              </span>
            </div>
          }
          className="btn-height w-full autocomplete-input rounded-sm"
          onSearch={onSearch}
        />
      </div>
      <Table
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        className="bg-white"
        scroll={{ x: '120px' }}
      />
    </>
  );
}

NotificationTable.defaultProps = {
  loading: false,
  data: []
}

export default NotificationTable