import React, { useEffect, useState } from 'react';
import { Table } from 'antd';

import './style.css';
import { useRecoilValue } from 'recoil';
import { HorizonRangeDataState } from 'app/bloc/atoms';
import {
  bgColor,
  getDayWeekMonthBetweenDates,
  isObject
} from 'app/utils/helpers';
import { TAny } from "../../../typings";

interface Props {
  data?: TAny[];
  granularity?: string;
  loading?: boolean;
}

const columns = [
  {
    title: 'Date',
    dataIndex: 'reportTitle',
    render: (text: string, record: TAny) => {
      if (!text) return <div style={{ height: '20px', fontWeight: 'bold' }}/>
      return (
        <span className={`m-0 ${record?.className || ''}`} style={{ fontWeight: record.key?.includes('highlight') ? 'bold' : ''}}>
          {text}
        </span>
      );
    },
    fixed: 'left',
    width: 130,
    className: 'bg-white text-black',
  },
] as Array<Record<string, unknown>>;

const FvaTable = ({ data, granularity, loading }: Props) => {
  const range = useRecoilValue(HorizonRangeDataState);
  const [tableColumns, setTableColumns] = useState(columns);

  useEffect(() => {
    if (range.length) {
      const days = getDayWeekMonthBetweenDates(granularity!, range)
      setTableColumns(columns.concat(
          days.map((day) => {
            return {
              title: day,
              dataIndex: day,
              render: (record: TAny) => {
                if (isObject(record)) return record.value;
                if (record === 0) return 0;
                return record;
              },
              onCell: (record: TAny) => {
                return { style: { backgroundColor: bgColor(record[day], record) } };
              },
              width: 40,
            };
          }),
      ),);
    }
  }, [range]);

  return (
      <Table
          className="border-none"
          columns={tableColumns}
          dataSource={data}
          pagination={false}
          scroll={{ x: 'max-content' }}
          loading={loading}
      />
  );
};

FvaTable.defaultProps = {
  data: [],
  granularity: '1',
  loading: false,
};

export default FvaTable;
