export const spaceRemover = (data?:string|null) => {
  let res = "";

  if(data){
    const lower = data?.toLowerCase();
    const upper = data?.toUpperCase();

    for(let i= 0; i < lower!.length; i += 1) {
      if(lower![i] !== upper![i] || lower![i].trim() === '')
        res += data![i];
    }
  }

  return res;
}