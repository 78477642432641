import React, { useState } from "react";
import { useRecoilState, useRecoilValue } from 'recoil';
import { Radio, RadioChangeEvent, Row, Select, Space } from "antd";
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

import {
  ForecastReportType,
  ForecastSelectionType,
  HorizonRangeDataState,
  HorizonRangeState,
  LagSelectionState,
  ReportDataState,
  TreeDataState
} from "app/bloc/atoms";
import SelectInput from 'app/__portions/SelectInput';
import ExportReportButton from 'app/__portions/ExportReportButton';
import { LAG_SELECTION } from "app/utils/helpers/constants";
import DropdownArrow from "app/__portions/_drawables/DropdownArrow";
import ReportTable from '../ReportTable';
import { IResponseAccuracyData, IResponseWaterfallData } from "../../../typings";
import LocalStorage from "../../../utils/helpers/LocalStorage";
import { spaceRemover } from "../../../utils/helpers/espaceRemover";

const FORECAST_SELECTION_TYPES = [
  { label: 'Barbados Forecast', value: 'statistical_forecast' },
  { label: 'External Forecast', value: 'external_forecast' }
];

const FORECAST_REPORT_TYPES = [
  { name: 'Forecast Accuracy Evol.', value: 'over_items' },
  { name: 'Forecast Waterfall'     , value: 'over_history' }
]

type Props = {
  data : IResponseAccuracyData | IResponseWaterfallData | undefined;
};

const ForecastReport= ({ data }:Props) => {
  const [report, setReport] = useRecoilState(ReportDataState)
  const [forecastType, setForecastType] = useRecoilState(
    ForecastReportType,
  )

  const [forecastSelectionType, setForecastSelectionType] = useRecoilState(
    ForecastSelectionType,
  )

  const horizonRange = useRecoilValue(HorizonRangeDataState);
  const { loading: treeLoading } = useRecoilValue(TreeDataState)
  const [exporting, setExporting] = useState(false)
  const [lagSelection, setLagSelection] = useRecoilState(LagSelectionState)
  const horizon = useRecoilValue(HorizonRangeState);

  const onAccuracyExport = () => {
    try {
      setExporting(true)

      const wsData = horizon.map((date) => ({
        Date    : date,
        Actual  : data?.actual       [date] || '-',
        Forecast: data?.forecast     [date] || '-',
        Lead    : data?.leads        [date] || '-',
        MAE     : data?.metrics.MAE  [date] || '-',
        BIAS    : data?.metrics.BIAS [date] || '-',
        RMSE    : data?.metrics.RMSE [date] || '-',
        MAPE    : data?.metrics.MAPE [date] || '-',
        wMAPE   : data?.metrics.wMAPE[date] || '-',
      }));

      const ws = XLSX.utils.json_to_sheet(wsData);
      const wb = XLSX.utils.book_new();

      XLSX.utils.sheet_add_aoa(ws, [["PRODUCT:"]], { origin: 'J2' });
      XLSX.utils.sheet_add_aoa(ws, [[spaceRemover(LocalStorage.getSelectedTreeKey())]], { origin: 'K2' });

      XLSX.utils.sheet_add_aoa(ws, [["LAG:"]], { origin: 'J3' });
      XLSX.utils.sheet_add_aoa(ws, [[lagSelection]], { origin: 'K3' });

      XLSX.utils.sheet_add_aoa(ws, [["FROM:"]], { origin: 'J4' });
      XLSX.utils.sheet_add_aoa(ws, [[horizonRange[0]]], { origin: 'K4' });

      XLSX.utils.sheet_add_aoa(ws, [["TO:"]], { origin: 'J5' });
      XLSX.utils.sheet_add_aoa(ws, [[horizonRange[1]]], { origin: 'K5' });

      XLSX.utils.sheet_add_aoa(ws, [["TYPE:"]], { origin: 'J6' });
      XLSX.utils.sheet_add_aoa(ws, [[forecastSelectionType]], { origin: 'K6' });

      XLSX.utils.book_append_sheet(wb, ws, `${forecastSelectionType}`);

      const payload = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      setExporting(false)
      saveAs(new Blob([payload], { type: "application/octet-stream" }), `Forecast-Accuracy-From-${horizonRange[0]}-To-${horizonRange[1]}.xlsx`);
    } catch (e) {
      setExporting(false)
      console.error(e)
    }
  };

  const onWaterfallExport = () => {
    try {
      setExporting(true)
      const forecastMap = (horizon: string) => {
        // @ts-ignore
        const forecast = Object.keys(data.forecast).map((date) => {
          return {
            [date]: data?.forecast[date][horizon] || "-"
          }
        })

        return Object.keys(forecast).reduce((acc, key) => {
          const [innerKey, value] = Object.entries(forecast[key])[0];
          acc[innerKey] = value;
          return acc;
        }, {})
      }

      const wsData = horizon.map((date) => ({
        Date  : date,
        Actual: data?.actual[date] || '-',
        ...forecastMap(date),
        Lead  : data?.leads        [date] || '-',
        MAE   : data?.metrics.MAE  [date] || '-',
        BIAS  : data?.metrics.BIAS [date] || '-',
        RMSE  : data?.metrics.RMSE [date] || '-',
        MAPE  : data?.metrics.MAPE [date] || '-',
        wMAPE : data?.metrics.wMAPE[date] || '-',
      }));

      const ws = XLSX.utils.json_to_sheet(wsData);
      const wb = XLSX.utils.book_new();
      const sheet = spaceRemover(LocalStorage.getSelectedTreeKey());

      const items = sheet.split(" ");
      const item = items.slice(-1)[0]
      XLSX.utils.book_append_sheet(wb, ws, `${item}`);

      const payload = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      setExporting(false)
      saveAs(new Blob([payload], { type: "application/octet-stream" }), `Waterfall-Report-Lag-${lagSelection}-from-${horizonRange[0]}-to-${horizonRange[1]}.xlsx`);
    } catch (e) {
      setExporting(false)
      console.error(e)
    }
  };

  const handleLagSelection = (value: number) => {
    setLagSelection(value)
    setReport((val) => {
      return ({ ...val, hasLoaded: false })
    });
  }

  const handleForecastSelection = ({ target: { value } }: RadioChangeEvent) => {
    setForecastSelectionType(value)
    setReport((val) => {
      return ({ ...val, hasLoaded: false })
    });
  };

  // @ts-ignore
  return (
    <div className="bg-white shadow-sm rounded-md p-5 my-5">
      <div className="px-2 ant-row justify-between">
        <div className="ant-row">
          <Space size="large">
            {forecastType !== "over_history" && (
              <Row>
                <Space>
                  <span style={{ whiteSpace: "nowrap", fontWeight: 800 }}>
                    Lag selection
                  </span>
                    <Select
                      className='rounded-sm text-gray select-35'
                      // @ts-ignore
                      suffixIcon={DropdownArrow}
                      defaultValue={lagSelection}
                      onChange={handleLagSelection}
                      options={LAG_SELECTION.map(size => ({ label: size, value: size }))}
                    />
                </Space>
              </Row>
            )}
            <Radio.Group
              className='rounded-sm text-gray select-35 bg-[#04abd3]'
              options={FORECAST_SELECTION_TYPES}
              onChange={handleForecastSelection}
              value={forecastSelectionType}
              optionType="button"
              // buttonStyle="solid"
            />
          </Space>
        </div>
        <div className="ant-row">
          <Space size="large">
            <SelectInput
              className="rounded-sm text-gray select-35"
              options={FORECAST_REPORT_TYPES}
              defaultValue={forecastType}
              onChange={(value) => {
                setForecastType(value);
                setReport((val) => {
                  return ({ ...val, hasLoaded: false });
                });
              }}
            />
            <ExportReportButton
              onExportToExcel={forecastType === "over_history" ? onWaterfallExport : onAccuracyExport }
              disabled={exporting}
              loading={exporting}
              data={data}
            />
          </Space>
        </div>
      </div>
      <div className="py-2">
        <ReportTable
          granularity={LocalStorage.getGranularity()}
          data={report.data}
          loading={treeLoading || report.loading}
        />
      </div>
    </div>
  );
};

export default ForecastReport;
