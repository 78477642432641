type kType = {
  DEFAULT_API: string;
  TOKEN_STORAGE_KEY: string;
  REFRESH_TOKEN_STORAGE_KEY: string;
  USER_ROLE_STORAGE: string;
  USER_ID: string;
  USER_NAME: string;
  USER_EMAIL: string;
  TREE_SELECTION_KEY: string;
  SELECTED_TREE_KEY: string;
  EXPANDED_TREE_KEY: string;
  GRANULARITY_KEY: string;
};

const keys: kType = {
  DEFAULT_API: process.env.DEFAULT_API as string || 'https://barbadosdevback.xeco.be',
  TOKEN_STORAGE_KEY: process.env.TOKEN_STORAGE_KEY as string || 'ju79J48_IOPW',
  REFRESH_TOKEN_STORAGE_KEY: process.env.REFRESH_TOKEN_STORAGE_KEY as string || 'fwhgvjh_UWNJ',
  USER_ROLE_STORAGE: process.env.USER_ROLE_STORAGE  as string || '84sks3U45UKDd',
  USER_ID: process.env.USER_ID as string || 'user_id',
  USER_NAME: process.env.USER_NAME as string || 'name',
  USER_EMAIL: process.env.USER_EMAIL as string || 'email',
  TREE_SELECTION_KEY: process.env.TREE_SELECTION_KEY  as string || 'tree_selection_key',
  SELECTED_TREE_KEY: process.env.SELECTED_TREE_KEY  as string || 'selected_tree_key',
  EXPANDED_TREE_KEY: process.env.EXPANDED_TREE_KEY  as string || 'expanded_tree_key',
  GRANULARITY_KEY: process.env.GRANULARITY_KEY  as string || 'granularity_key',
};
export default keys;