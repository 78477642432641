import moment from "moment";
import {
  DAILY_DISPLAY_FORMAT,
  MONTHLY_DISPLAY_FORMAT,
  WEEKLY_DISPLAY_FORMAT
} from "../../../utils/helpers/constants";

/**
 return [
   { "date": "Apr-16->Apr-22", "value": 38 },
   { "date": "Apr-23->Apr-29", "value": 34 },
   { "date": "Apr-30->May-06", "value": 33 },
   { "date": "May-07->May-13", "value": 36 },
   { "date": "May-14->May-16", "value": 15 }
 ]
 * granularity: Weekly
 * @param data
 */
interface DailyData { [key: string]: number }

export const groupByWeek = (data: object) => {
  if(!data) return []
  const dailyArray = Object.entries(data).map(([date, value]) => ({
    date: moment(date, DAILY_DISPLAY_FORMAT),
    value
  }));

  const groupedWeeklyData: DailyData[] = [];
  let currentWeekStart   : moment.Moment;
  let currentWeekEnd     : moment.Moment;
  let currentWeekValue = 0;

  // @ts-ignore
  dailyArray.sort((a, b) => a.date - b.date).forEach((daily) => {
    const startOfWeek = daily.date.clone().startOf('isoWeek');
    const endOfWeek = daily.date.clone().endOf('isoWeek');

    if (!currentWeekStart || currentWeekStart.isBefore(startOfWeek)) {
      if (currentWeekStart) {
        groupedWeeklyData.push({[`${currentWeekStart.format(WEEKLY_DISPLAY_FORMAT)}->${currentWeekEnd.format(WEEKLY_DISPLAY_FORMAT)}`]: currentWeekValue});
      }
      currentWeekStart = startOfWeek;
      currentWeekEnd   = endOfWeek;
      currentWeekValue = daily.value;
    } else {
      currentWeekValue += daily.value;
    }
  });

  // @ts-ignore
  if (currentWeekStart) {
    // @ts-ignore
    groupedWeeklyData.push({[`${currentWeekStart.format(WEEKLY_DISPLAY_FORMAT)}->${currentWeekEnd.format(WEEKLY_DISPLAY_FORMAT)}`]: currentWeekValue});
  }

  return groupedWeeklyData;
};

interface MonthProps {
  data     : object;
  isNumber?: boolean;
}

/**
 * return [
 *     { "date": "04-01", "value": 100 },
 *     { "date": "05-01", "value": 64 }
 *   ]
 * granularity: Monthly
 * @param props
 */

export function groupByMonth(props: MonthProps){
  const { data, isNumber= true } = props
  const monthlyData: Record<string, number> | Record<string, string> = {};

  for (const [date, value] of Object.entries(data)) {
    const month = moment(date, MONTHLY_DISPLAY_FORMAT).format(MONTHLY_DISPLAY_FORMAT);
    if (!monthlyData[month]) {
      monthlyData[month] = isNumber ? 0 : '';
    }
    monthlyData[month] += isNumber ? value : parseFloat(value.replace('%', ''));
  }

  // eslint-disable-next-line prefer-template
  return Object.entries(monthlyData).map(([date, value]) => ({ [date]: isNumber ? value : value + '%' }));
}