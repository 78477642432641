import { Modal } from 'antd';
import React from 'react';
import { RiCloseCircleFill } from 'react-icons/ri';

import './style.css';

interface IModalWrapper {
  title?: React.ReactNode;
  visible?: boolean;
  centered?: boolean;
  closable?: boolean;
  destroyOnClose?: boolean;
  onCancel?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  footer?: React.ReactNode;
  className?: string;
  width?: number;
  height?: number;
  children: React.ReactNode | React.ReactNode[];
}

const ModalWrapper = ({
  title,
  visible,
  centered,
  onCancel,
  footer,
  className,
  closable,
  destroyOnClose,
  children,
  width,
  height,
}: IModalWrapper) => {
  const style = {
    '--modal-width': width ? `${width}px` : undefined,
    '--modal-height': height ? `${height}px` : undefined,
  } as React.CSSProperties;

  return (
    <Modal
      title={title}
      visible={visible}
      centered={centered}
      onCancel={onCancel}
      footer={footer}
      className={`modal-wrapper ${className}`}
      closeIcon={<RiCloseCircleFill size="24px" />}
      closable={closable}
      style={style}
      destroyOnClose={destroyOnClose}
    >
      {children}
    </Modal>
  );
};

ModalWrapper.defaultProps = {
  title: null,
  visible: false,
  centered: true,
  onCancel: () => null,
  footer: null,
  className: '',
  width: 310,
  height: undefined,
  closable: true,
  destroyOnClose: false,
};

export default ModalWrapper;
