import React, { Key, useEffect } from "react";
import { Tree, Space, Skeleton } from 'antd';
import { useRecoilValue } from "recoil";
import { useLocation } from 'react-router-dom';
import { TAny, TSupportedKeys } from "app/typings";
import useTreeData from 'app/Hooks/useTreeData';
import { TreeDataState } from "app/bloc/atoms";
import LocalStorage from "../../utils/helpers/LocalStorage";
import paths from "../../routes/paths";

interface Props {
  selectedKeys?: Key[];
  expandedKeys?: Key[];
  expandedTreeKey?: TSupportedKeys;
  onSelect?: (keys: Key[], info: TAny) => void;
  onExpand?: (keys: Key[], info: TAny) => void;
}

const TreeNavigator = ({
    selectedKeys,
    expandedKeys,
    expandedTreeKey,
    onSelect,
    onExpand,
}: Props) => {

  const { treeData, loading, hasLoaded } = useRecoilValue(TreeDataState);
  const [, fetchTreeData] = useTreeData();
  const location = useLocation();

  // @ts-ignore
  const selectedKey = JSON.parse(LocalStorage.getSelectedTreeKey())
  // @ts-ignore
  const expandedKey = JSON.parse(LocalStorage.getExpandedTreeKey())

  const defaultSelected = (selectedKey === null || selectedKey === '') ?
    [treeData?.[0]?.children?.[0]?.children?.[0]?.children?.[0]?.children?.[0]?.children?.[0].key as string] :
    selectedKey;

  const defaultExpanded = (expandedKey === null || expandedKey === '') ?
    [treeData?.[0]?.children?.[0]?.children?.[0]?.children?.[0]?.children?.[0].key as string] :
    expandedKey;

  const onSelectTree = (keys: Key[], info: TAny) => {
    onSelect?.(keys, info);
  };

  const onExpandTree = (keys: Key[], info: TAny) => {
    onExpand?.(keys, info);
  };

  useEffect(() => {
    if (!hasLoaded) {
      fetchTreeData();
    }
  }, [fetchTreeData, hasLoaded]);

  return (
    <div className="py-4 px-1">
      {(location.pathname === paths.DEMAND_PLANNING) && (
        <div className="ant-row ant-space-align-center">
          <div style={{ width: '10px', height: '10px', borderRadius: '10px', backgroundColor: 'green' }} />
          <div style={{ marginLeft: '0.5rem' }}>Demand Planning</div>
        </div>
      )}
      {(loading || treeData.length === 0) ? (
        <>
          <Space className="mb-2">
            <Skeleton.Input style={{ width: 24, borderRadius: 8 }} active size="small"/>
            <Skeleton.Input style={{ width: 175, borderRadius: 8 }} active size="small"/>
          </Space>
          {Array.from({ length: 15 }).map((_, index) => (
            <Space key={index.toFixed()} className="mb-2">
              <Skeleton.Input style={{ width: 24, marginLeft: 20, borderRadius: 8 }} active size="small"/>
              <Skeleton.Input style={{ width: 175, borderRadius: 8 }} active size="small"/>
            </Space>
          ))}
        </>
      ) : (
        <Tree
          showLine
          blockNode
          treeData={treeData}
          defaultSelectedKeys={
            selectedKeys?.length ? selectedKeys : defaultSelected
          }
          defaultExpandedKeys={
            expandedKeys?.length ? expandedTreeKey : defaultExpanded
          }
          onSelect={onSelectTree}
          onExpand={onExpandTree}
        />
      )}
    </div>
  );
};

TreeNavigator.defaultProps = {
  onSelect: undefined,
  onExpand: undefined,
  selectedKeys: [],
  expandedTreeKey: 'reportKeys',
  expandedKeys: [],
};

export default TreeNavigator;
