import React from "react";
import ModalWrapper from "../../ModalWrapper";

interface IProps {
  visible?: boolean,
  title?: string | undefined,
  width?: number,
  children: React.ReactNode | React.ReactNode[];
  onCancel?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const SimpleDialog = ({visible, title, width = 400, children, onCancel}:IProps) => {
  return <ModalWrapper
    width={width}
    visible={visible}
    onCancel={onCancel}
  >
    <h3>{title}</h3>
    {children}
  </ModalWrapper>
}

SimpleDialog.defaultProps = {
  title: '',
  visible: false,
  onCancel: () => null,
  width: 310,
};

export default SimpleDialog