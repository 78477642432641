import moment from "moment";
import readXlsxFile from "read-excel-file";
import { TAny } from "app/typings";
import { ValidationError, generateDateValueMap, getGranularity, getSelectedGranularityValue } from "./index";

import { DAILY_DATE_FORMAT } from "./constants";

const excelNumberToDate = (value: unknown) => {
  const isDate = value instanceof Date && !Number.isNaN(value);
  if (isDate) {
    return moment(value).format(DAILY_DATE_FORMAT);
  }
  if (typeof value === 'number') {
    return moment(new Date(Date.UTC(0, 0, value - 1))).format(
      DAILY_DATE_FORMAT,
    );
  }
  return value;
};

export const getPayload = async (file: File, name: string, type: string) => {
  try {
    const rows = await readXlsxFile(file);

    const startIndex = rows.findIndex((row: Array<TAny>) => {
      return row.some((value: TAny) => value === 'Region');
    });

    const isForecast = type === 'forecast';

    const dataColumnsStart = isForecast ? 7 : 6;
    const datesRow = rows[startIndex]
      .slice(dataColumnsStart)
      .map(excelNumberToDate);

    rows.splice(0, startIndex + 1);

    if (
      isForecast &&
      typeof rows[0]?.[1] === 'string' &&
      typeof rows[0]?.[2] === 'string' &&
      (rows[0]?.[1]?.replace(/\s+/g, '') === 'E.G.Cartridge' ||
        rows[0]?.[2]?.replace(/\s+/g, '') === 'E.G.USA')
    ) {
      rows.splice(0, 1);
    }

    if (
      !isForecast &&
      typeof rows[0]?.[0] === 'string' &&
      typeof rows[0]?.[1] === 'string' &&
      (rows[0]?.[0]?.replace(/\s+/g, '') === 'E.G.Cartridge' ||
        rows[0]?.[1]?.replace(/\s+/g, '') === 'E.G.USA')
    ) {
      rows.splice(0, 1);
    }

    type IProps = {
      date: string,
      value: number
    }

    return rows.map((row, index) => {
      let data
      const dataWeekMonth: IProps[] = []

      switch (getSelectedGranularityValue()) {
        case "daily":
          data = row
            .slice(dataColumnsStart)
            .map((value, index) => {
              return {
                date: datesRow[index] as string,
                value: typeof value === 'number' ? Math.trunc(value) : 0,
              };
            })
            .filter((d) => d.date);
          break;
        case "weekly":
        case "monthly":
          row
            .slice(dataColumnsStart)
            // eslint-disable-next-line array-callback-return
            .map((value, index) => {
              const d = generateDateValueMap({
                date: datesRow[index] as string,
                value: typeof value === 'number' ? Math.trunc(value) : 0,
              })
              // @ts-ignore
              dataWeekMonth.push(...d)
            })
            // @ts-ignore
            .filter((d) => d?.date);
          break;
        default:
          data = row
            .slice(dataColumnsStart)
            .map((value, index) => {
              return {
                date: datesRow[index] as string,
                value: typeof value === 'number' ? Math.trunc(value) : 0,
              };
            })
            .filter((d) => d.date);
      }

      if (isForecast) {
        const madeOn = excelNumberToDate(row[0]);
        if (madeOn === row[0]) {
          throw new ValidationError(
            `The 'Forecast month' is required and must be a date on row ${index + 1
            }`,
          );
        }
        return {
          made_on  : madeOn,
          line     : row[1] || 'N/A',
          region   : row[2] || 'N/A',
          group    : row[3] || 'N/A',
          category : row[4] || 'N/A',
          item     : row[5] || 'N/A',
          reference: row[6] || '',
          data     : getGranularity() === 'date' ? data : dataWeekMonth,
          file_name: name,
        };
      }

      return {
        line     : row[0] || 'N/A',
        region   : row[1] || 'N/A',
        group    : row[2] || 'N/A',
        category : row[3] || 'N/A',
        item     : row[4] || 'N/A',
        reference: row[5] || '',
        data     : getGranularity() === 'date' ? data : dataWeekMonth,
        file_name: name,
      };
    });
  } catch (error) {
    console.error(error)
    return Promise.reject(error);
  }
};
