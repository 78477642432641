import React, { useState } from "react";

import { useRecoilState } from "recoil";
import {
  BsCalendar,
  BsCalendarWeek,
  BsCalendarMonth
} from "react-icons/bs";
import { useHistory } from "react-router-dom";
import Scaffold from 'app/__portions/Scaffold';
import SettingSideMenu from "../../__portions/SideMenus/SettingSideMenu";
import CardGranularity from "./components/CardGranularity";

import { GranularitySelectionType } from "../../bloc/atoms";
import LocalStorage from "../../utils/helpers/LocalStorage";
import Api from "../../Services";
import ENDPOINTS from "../../Services/endpoints";
import { getSelectedGranularity } from "../../utils/helpers";

const Settings = () => {
  const [, setGranularitySelection] = useRecoilState(GranularitySelectionType);
  const [selected, setSelected] = useState(LocalStorage.getGranularity);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleReloadPrevious = () => {
    history.goBack();
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };

  const changeGranularity = async (index: string) => {
    setLoading(true)
    const payload = {
      granularity: getSelectedGranularity(index),
      adjustment_lock_days: 5,
    }

    await Api.put(ENDPOINTS.APP_SETTINGS, payload);
    setLoading(false)

    handleReloadPrevious()
  }

  const handlerSelection = (index: string) => {
    setSelected(index);
    LocalStorage.setGranularity(index);
    setGranularitySelection(getSelectedGranularity(index))

    changeGranularity(getSelectedGranularity(index));
  }

  return (
      <Scaffold sideBar={<SettingSideMenu />}>
        <div className="ant-row ant-row-space-between ant-space-align-center mb-2">
          <h1 className="text-lg font-bold letter-28 text-black">SETTINGS</h1>
        </div>

        <div className="bg-white rounded-md p-4 flex flex-nowrap">
          <div className="flex-1 animate-all-500 p-4">
            <span className="text-black font-bold text-lg">Choose the granularity</span>
            <div className='mt-3 p-4 display-grid'>
              <CardGranularity
                icon={<BsCalendar size="72px" />}
                title='Daily'
                format='yyyy-mm-dd'
                index='1'
                loading={loading}
                selected={selected === '1'}
                onSelect={(index) => handlerSelection(index)}
              />
              <CardGranularity
                icon={<BsCalendarWeek size="72px" />}
                title='Weekly'
                format='yyyy-ww'
                index='2'
                loading={loading}
                selected={selected === '2'}
                onSelect={(index) => handlerSelection(index)}
              />
              <CardGranularity
                icon={<BsCalendarMonth size="72px" />}
                title='Monthly'
                format='yyyy-mm'
                index='3'
                loading={loading}
                selected={selected === '3'}
                onSelect={(index) => handlerSelection(index)}
              />
            </div>
          </div>
        </div>
      </Scaffold>
    );
};

export default Settings;

