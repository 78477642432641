import React, {Key, ReactNode, useEffect, useRef, useState } from 'react';
import { Button, Layout, PageHeader, Result } from "antd";
import { useHistory } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { GiHamburgerMenu } from "react-icons/gi";
import { TAny, TSupportedKeys } from "app/typings";
import {
  CollapseSider,
  ErrorsState
} from "app/bloc/atoms";
import TreeNavigator from '../TreeNavigator';
import TopNavbar from './TopNavbar';
import {getSelectedGranularityValue} from "../../utils/helpers";
import SimpleDialog from "../Dialog/SimpleDialog";
import paths from "../../routes/paths";

const { Sider } = Layout;

interface Props {
  children: ReactNode | ReactNode[];
  expandedTreeKey?: TSupportedKeys;
  selectedKeys?: Key[];
  expandedKeys?: Key[];
  sideBar?: ReactNode;
  hideSideBarInMedium?: boolean;
  onSelect?: (keys: Key[], info: TAny) => void;
  onExpand?: (keys: Key[], info: TAny) => void;
}

const Scaffold = ({
  children,
  selectedKeys,
  expandedTreeKey,
  expandedKeys,
  sideBar,
  hideSideBarInMedium,
  onSelect,
  onExpand,
}: Props) => {
  const siderRef = useRef<HTMLDivElement>(null);
  const errorsState = useRecoilValue(ErrorsState);
  const setErrorState = useSetRecoilState(ErrorsState);
  const collapsed = useRecoilValue(CollapseSider);
  const [showTreeNavigator, setShowTreeNavigator] = useState(true);
  const siderClassName = showTreeNavigator ? 'hidden lg:block' : 'hidden';
  const history = useHistory();

  const onToggleNavigator = () => {
    siderRef.current?.classList.toggle('hidden');
  };

  useEffect(() => {
    const hasError = !!errorsState.aggregationError?.error && !!errorsState.aggregationError?.message;

    setShowTreeNavigator(!hasError);
  }, [errorsState.aggregationError, setShowTreeNavigator]);

  const handleCancel = () => {
    setErrorState((errors) => ({
      ...errors,
      aggregationError: {error: null, message: null},
      isServerDown: false,
    }));
  }

  const handleRetry = () => {
    handleCancel()
    history.replace(paths.HOME_PATH)
  }

  return (
    <Layout style={{ height: '100vh', overflowX: 'auto' }}>
      <PageHeader
        className="site-page-header-responsive border-b p-0 pl-2 md:pr-4"
        backIcon={false}
        title={
          <span className='flex'>
              {hideSideBarInMedium && (
                <div
                  key="navigator"
                  className='lg:hidden pr-2'
                  tabIndex={0}
                  role="button"
                  onKeyDown={() => null}
                  onClick={onToggleNavigator}
                >
                  <GiHamburgerMenu size="22.4px" />
                </div>
              )}
            <span>BARBADOS<span className="text-gray text-sm"> | {getSelectedGranularityValue()}</span></span>
          </span>
        }
        extra={ <TopNavbar/> }
      />
      <Layout>
        <Sider
          ref={siderRef}
          collapsed={collapsed}
          className={`${ hideSideBarInMedium ? siderClassName : '' } navigator-panel`}
        >
          {sideBar || (
            <TreeNavigator
              onSelect={onSelect}
              onExpand={onExpand}
              expandedTreeKey={expandedTreeKey}
              selectedKeys={selectedKeys}
              expandedKeys={expandedKeys}
            />
          )}
        </Sider>

        <Layout>
          <div className="p-2 md:p-4 pb-16 overflow-y-auto w-full">
            {(!errorsState.aggregationError?.error && !errorsState.aggregationError?.message && children) || null}

            {(errorsState.isServerDown && (
              <SimpleDialog
                visible
                width={400}
                onCancel={handleCancel}>
                <Result
                  status="500"
                  title={errorsState?.aggregationError?.error || 'An error occurred'}
                  subTitle={
                    <div>
                      <h4>
                        {errorsState?.aggregationError?.message}
                      </h4>
                      <p>We are sorry for inconvenience.</p>
                    </div>
                  }
                  extra={
                    <Button type="primary" onClick={handleRetry}>
                      Please refresh the page
                    </Button>
                  }
                />
              </SimpleDialog>
            )) || null }

          </div>
        </Layout>
      </Layout>
    </Layout>
  );
};

Scaffold.defaultProps = {
  onSelect: undefined,
  onExpand: undefined,
  expandedTreeKey: "reportKeys",
  selectedKeys: [],
  expandedKeys: [],
  sideBar: null,
  hideSideBarInMedium: true,
};

export default Scaffold;
