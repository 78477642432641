import React from 'react';
import { Skeleton } from 'antd';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
} from 'chart.js';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import { IChartLine, TAny } from "app/typings";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
  },
};

interface Props {
  data    : IChartLine;
  loading?: boolean;
}

interface DataProps {
  name  : string;
  Actual  : Record<string, number>;
  Model   : Record<string, number>;
  Forecast: Record<string, number>;
}

const DemandPlanningChart = ({ data, loading }: Props) => {
  if (loading) {
    return <Skeleton />;
  }

  const datas: DataProps[] = [];

  /* eslint-disable eslint-comments/disable-enable-pair */
  /* eslint-disable array-callback-return */
  Object(data.datasets).map((dataset:TAny) => {
    Object.keys(dataset.data).forEach((key) => {
      const existingData = datas.find((data) => data.name === key)
      if (existingData) {
        existingData[dataset.label] = dataset.data[key]
      } else {
        datas.push({
          name: key,
          Actual  : dataset.label === "Actual"   ? dataset.data[key] : 0,
          Model   : dataset.label === "Model"    ? dataset.data[key] : 0,
          Forecast: dataset.label === "Forecast" ? dataset.data[key] : 0,
        })
      }
    })
  })

  return <ResponsiveContainer width="100%" height="100%">
    <LineChart data={datas}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip cursor={{ stroke: 'gray', strokeWidth: 2 }} />
      <Legend />
      <Line type="monotone" dataKey="Actual"   stroke="#219ebc" activeDot={{ r: 6 }} strokeWidth={3}/>
      <Line type="monotone" dataKey="Model"    stroke="#023047" activeDot={{ r: 6 }} strokeWidth={3}/>
      <Line type="monotone" dataKey="Forecast" stroke="#ffb703" activeDot={{ r: 6 }} strokeWidth={3}/>
    </LineChart>
  </ResponsiveContainer>
};

DemandPlanningChart.defaultProps = {
  loading: false,
};

export default DemandPlanningChart;
