import { atom } from 'recoil';
import moment from 'moment';
import {
  IErrorState,
  ITree,
  TAny,
  RowType,
  ISelectedKeys,
  IAnalytics,
  TForecastTableType,
  IDemandPlanning,
  INode,
  IReportTableData,
  IMutateUserState,
  TSupportedKeys,
  ICapacityDemand,
  INotificationResponse,
  TForecastSelectionType, IFvaTableData, TGranularitySelectionType, IUserState
} from "app/typings";
import {
  DEFAULT_SELECTED_NODE,
  DEFAULT_SELECTION_KEYS,
  DEMAND_PLANNING_DEFAULT,
} from './defaults';
import {
  MONTHLY_DATE_FORMAT
} from "../utils/helpers/constants";

export const TreeDataState = atom<{
  treeData: ITree[];
  treeDataResponse: Array<Record<string, TAny>>;
  loading?: boolean;
  hasLoaded?: boolean;
}>({
  key: 'treeData',
  default: {
    treeData: [],
    treeDataResponse: [],
    loading: false,
    hasLoaded: false,
  },
});

export const ReportDataState = atom<{
  loading: boolean;
  hasLoaded: boolean;
  data: RowType[];
}>({
  key: 'reportData',
  default: { loading: false, hasLoaded: false, data: [] },
});

export const FvaDataState = atom<{
  loading  : boolean;
  hasLoaded: boolean;
  data     : RowType[];
}>({
  key: 'fvaData',
  default: { loading: false, hasLoaded: false, data: [] },
});

export const selectedKeysState = atom<ISelectedKeys>({
  key: 'selectedKeys',
  default: DEFAULT_SELECTION_KEYS,
});

export const expandedKeysState = atom<ISelectedKeys>({
  key: 'expandedKeys',
  default: DEFAULT_SELECTION_KEYS,
});

export const HorizonRangeDataState = atom<string[]>({
  key: 'horizonRangeData',
  default: [
    moment().subtract(14, 'days').format('YYYY-MM-DD'),
    moment().format('YYYY-MM-DD'),
  ],
});

export const HorizonRangeState = atom<string[]>({
  key: 'horizonRange',
  default: [],
});

export const ErrorsState = atom<IErrorState>({
  key: 'errorState',
  default: {
    aggregationError: {
      error: null,
      message: null,
    },
    isServerDown: false,
  },
});

export const AnalyticsState = atom<IAnalytics>({
  key: 'analytics',
  default: {
    hasLoaded: false,
    fiscalData: [],
    currentYear: moment().format('YYYY-MM-DD'),
    projectionData: [],
    graphAnalytics: { datasets: [] },
  },
});

export const GranularitySelectionType = atom<TGranularitySelectionType>({
  key: 'GranularitySelectionType',
  default: 'daily',
});

export const ForecastSelectionType = atom<TForecastSelectionType>({
  key: 'ForecastSelectionType',
  default: 'statistical_forecast',
});

export const ForecastReportType = atom<TForecastTableType>({
  key: 'ForecastReportType',
  default: 'over_items',
});

export const DemandPlanningState = atom<IDemandPlanning>({
  key: 'DemandPlanning',
  default: DEMAND_PLANNING_DEFAULT,
});

export const SelectedNode = atom<{
  [key in TSupportedKeys]: Partial<INode>;
}>({
  key: 'selectedNode',
  default: {
    analyticsKeys: DEFAULT_SELECTED_NODE,
    planningKeys: DEFAULT_SELECTED_NODE,
    reportKeys: DEFAULT_SELECTED_NODE,
    capacityKeys: DEFAULT_SELECTED_NODE,
  },
});

export const ReportTableData = atom<IReportTableData>({
  key: 'reportTableData',
  default: {
    actualRow   : {} as RowType,
    forecastRows: [] as RowType[],
    historyRows : [] as RowType[],
    leadRow     : {} as RowType | undefined,
    metricRows  : [] as RowType[] | undefined,
    dataLoaded  : false,
    forecastEvol: {},
  },
});

export const FvaTableData = atom<IFvaTableData>({
  key: 'fvaTableData',
  default: {
    actualRow          : {} as RowType,
    statForecastRow    : {} as RowType,
    plannerForecastRow : {} as RowType,
    salesRepForecastRow: {} as RowType,
    statMapeRow        : {} as RowType,
    plannerMapeRow     : {} as RowType,
    salesRepMapeRow    : {} as RowType,
    salesRepFvaRow     : {} as RowType,
    plannerFvaRow      : {} as RowType,
    dataLoaded: false,
  },
});

export const UsersState = atom<IMutateUserState>({
  key: 'usersState',
  default: {
    loading: false,
    showDetails: false,
    hasLoaded: false,
    currentUser: {},
    isCreate: false,
    isEdit: false,
    users: [],
  },
});

export const UserState = atom<IUserState>({
  key: 'userState',
  default: {
    loading: false,
    showDetails: false,
    hasLoaded: false,
    currentUser: {},
    isEdit: false,
  },
});

export const CollapseSider = atom<boolean>({
  key: 'collapseSider',
  default: false,
});

export const CapacityState = atom<ICapacityDemand>({
  key: 'capacityState',
  default: {
    loading: true,
    hasLoaded: false,
    unit: 0,
    dateFilter: [
      moment().format(MONTHLY_DATE_FORMAT),
      moment().add(3, 'months').format(MONTHLY_DATE_FORMAT),
    ],
    currentChart: 0,
    capacities: {
      items: 1,
      months: [],
      weeks: [],
      days: [],
    },
  },
});

export const NotificationState = atom<INotificationResponse>({
  key: 'notificationState',
  default: {
    loading: false,
    items: 0,
    data: [],
  },
})

export const LagSelectionState = atom<number>({
  key: 'lagSelectionState',
  default: 3,
});
