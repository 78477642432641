import './style.css';

import { Alert, Button, Form, Input } from 'antd';
import { HiOutlineLockClosed, HiOutlineMail } from 'react-icons/hi';
import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import Api from 'app/Services';
import ENDPOINTS from 'app/Services/endpoints';
import { ErrorsState } from 'app/bloc/atoms';
import LocalStorage from 'app/utils/helpers/LocalStorage';
import { USER_ROLES } from 'app/utils/helpers/constants';
import keys from 'app/utils/configs/keys';

const Login = () => {
  const [form] = Form.useForm();
  const history = useHistory();

  const setErrorState = useSetRecoilState(ErrorsState);

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const previousPath = history.location?.state?.from

  const onFinish = async (values: { email: string; password: string; }) => {
    setLoading(true);

    const { data, status, error } = await Api.post(ENDPOINTS.LOGIN, values);

    if (error) {
      setMessage(error);
      setLoading(false);
      return;
    }

    if (status === 400) {
      setMessage('Something went wrong. Please try again.');
      setLoading(false);
      return;
    }

    if (data) {
      setLoading(false);
      setErrorState({
        aggregationError: {
          error: null,
          message: null,
        },
        isServerDown: false,
      });

      LocalStorage.removeAccessToken();
      LocalStorage.removeRefreshToken();

      LocalStorage.setAccessToken(data.token);
      LocalStorage.setRefreshToken(data.refresh_token);

      if (data.user_id) {
        LocalStorage.set(keys.USER_ID, data.user_id);
      }

      if (data.name) {
        LocalStorage.set(keys.USER_NAME, data.name);
      }

      if (data.email) {
        LocalStorage.set(keys.USER_EMAIL, data.email);
      }

      if (data.role) {
        LocalStorage.set(keys.USER_ROLE_STORAGE, USER_ROLES[data.role].value);
      }

      if (LocalStorage.getAccessToken()) {
        history.push(previousPath?.pathname || '/report');
      }
    }
  };

  return (
    <section className="h-screen flex ant-row-center ant-space-align-center bg-secondary">
      <div className="bg-white shadow-xs p-4 py-4 rounded-md login-wrapper">
        <p className="text-lg ant-form-text text-black font-bold mb-3">LOGIN</p>
        {message ? (
          <Alert
            message={message}
            type="error"
            showIcon
            style={{ marginBottom: '16px' }}
          />
        ) : null}
        <Form form={form} onFinish={onFinish}>
          <Form.Item
            name="email"
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              {
                required: true,
                message: 'Please input your E-mail!',
              },
            ]}
          >
            <Input
              prefix={
                <HiOutlineMail className="site-form-item-icon" />
              }
              placeholder="Email"
              size="large"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
          >
            <Input.Password
              prefix={
                <HiOutlineLockClosed className="site-form-item-icon" />
              }
              type="password"
              placeholder="Password"
              size="large"
            />
          </Form.Item>
          <Form.Item shouldUpdate className="w-full">
            {() => (
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                className="w-full"
                disabled={loading}
              >
                Log in
              </Button>
            )}
          </Form.Item>
        </Form>
      </div>
    </section>
  );
};

export default Login;
