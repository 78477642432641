import React from 'react';
import { IoAnalyticsSharp } from 'react-icons/io5';
import { Row } from 'antd';
import SwitcherIcon from 'app/__portions/SwitcherIcon';
import { TAny} from "app/typings";


export const toTreeModel = (line) => {
  return {
    title: (
        <Row justify="space-between" align="middle">
          <div>{line.name}</div>
          <IoAnalyticsSharp size="24px" />
        </Row>
    ),
    name: line.name,
    selected: line.selected,
    type: 'line',
    key: `${line.name}}`,
    switcherIcon: () => <SwitcherIcon color="#0051b6" />,
    children: line.regions.map((region) => {
      return {
        title: (
            <Row justify="space-between" align="middle">
              <div>{region.name}</div>
              <IoAnalyticsSharp size="24px" />
            </Row>
        ),
        key: `${line.name}_${region.name}`,
        name: region.name,
        lineName: line.name,
        selected: region.selected,
        type: 'region',
        switcherIcon: () => <SwitcherIcon color="#006988" />,
        children: region.groups.map((group) => ({
          key: `${line.name}_${region.name}_${region.name}_${group.name}`,
          title: group.name,
          name: group.name,
          selected: group.selected,
          switcherIcon: <SwitcherIcon color="#00b4d8" />,
          type: 'group',
          lineName: line.name,
          regionName: region.name,
          children: group.categories.map((category) => ({
            key: `${line.name}_${region.name}_${group.name}_${category.name}`,
            title: category.name,
            name: category.name,
            selected: category.selected,
            switcherIcon: <SwitcherIcon color="#80c6d3" />,
            type: 'category',
            lineName: line.name,
            regionName: region.name,
            children: category.items.map((item) => ({
              key: `${line.name}_${region.name}_${group.name}_${category.name}_${item.name}`,
              title: item.name,
              name: item.name,
              selected: item.selected,
              switcherIcon: <SwitcherIcon color="#90e0ef" />,
              type: 'item',
              lineName: line.name,
              regionName: region.name,
            })),
          })),
        })),
      };
    }),
  };
};

export const allProductsNode = {
  title: (
    <Row justify="space-between" align="middle">
      <div>All Sales</div>
      <IoAnalyticsSharp size="16px" />
    </Row>
  ),
  key: 'All-products',
  selected: false,
  type: 'all-products',
  name: 'All Sales',
  switcherIcon: () => <SwitcherIcon />,
} as TAny;
