import { Layout, Result, Skeleton, Space } from "antd";
import React, { Key, useCallback, useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  FvaTableData,
  HorizonRangeDataState, HorizonRangeState, LagSelectionState,
  FvaDataState,
  selectedKeysState,
  TreeDataState, ErrorsState
} from "app/bloc/atoms";
import {
  getFetchDataTreePayload,
  getColumnRanges,
  getFvaRow
} from "app/utils/helpers";
import ForecastHorizon from "app/__portions/Selectors/ForecastHorizon";
import { IResponseFvaData, RowType, TAny } from "app/typings";
import Scaffold from "app/__portions/Scaffold";
import LocalStorage from "../../utils/helpers/LocalStorage";
import ForecastFva from "./ForecastFva";
import LagSelection from "../../__portions/Selectors/LagSelection";
import Api from "../../Services";
import ENDPOINTS from "../../Services/endpoints";

const Fva = () => {
  const setErrorState = useSetRecoilState(ErrorsState);
  const printRef = useRef<HTMLDivElement>(null)
  const [fvaData, setFvaData] = useRecoilState(FvaTableData);

  const treeDataState  = useRecoilValue(TreeDataState);
  const horizonRange   = useRecoilValue(HorizonRangeDataState);
  const [, setHorizon] = useRecoilState(HorizonRangeState);
  const [{ loading, hasLoaded }, setFva] = useRecoilState(FvaDataState);

  const [{ reportKeys }, setSelectedKeys] = useRecoilState(selectedKeysState);

  const lagSelection = useRecoilValue(LagSelectionState);

  const [lag, setLag] = useState('');
  const [responseBody, setResponseBody] = useState<IResponseFvaData>();

  const fetchData = useCallback(async (lines:Record<string, TAny>[] = treeDataState.treeDataResponse) => {
        const payload = {
          lines,
          start_date: `${horizonRange[0]}`,
          end_date  : `${horizonRange[1]}`,
          lag_number: lagSelection,
        };

        setFva((prev) => {
          return { ...prev, loading: true };
        });

        const { data: responseData, message, status } = await Api.post(
          ENDPOINTS.FETCH_FVA,
          payload
        );

      if (status === 404 || status === 500) {
        setErrorState((errors) => ({
          ...errors,
          aggregationError: {error: 'An error occurred', message},
          isServerDown: true,
        }));
        return;
      }

        if (responseData) {
          const columnsRange = getColumnRanges(horizonRange[0], horizonRange[1])

          const {
            actual,
            statistical_forecast,
            planner_forecast,
            sales_rep_forecast,
            statistical_mape,
            planner_mape,
            sales_rep_mape,
            fva_sales_rep_mape,
            fva_planner_mape
          } = responseData;

          setLag(columnsRange[0])
          setFva((prev) => {
            return {...prev, responseData }
          })
          setResponseBody(responseData)
          setHorizon(columnsRange)

          const actualRow = getFvaRow(
              'actual highlight',
              'Actual',
              actual
          )

          const statForecastRow = getFvaRow(
              'statistical_forecast',
             'Stat',
              statistical_forecast
          )

          const plannerForecastRow = getFvaRow(
              'planner_forecast',
              'Planner',
              planner_forecast
          )

          const salesRepForecastRow = getFvaRow(
              'sales_rep_forecast',
              'Sales',
              sales_rep_forecast
          )

          const statMapeRow = getFvaRow(
              'statistical_mape',
              'Stat MAPE',
              statistical_mape
          )

          const plannerMapeRow = getFvaRow(
              'planner_mape',
              'Planner MAPE',
              planner_mape
          )

          const salesRepMapeRow = getFvaRow(
              'sales_rep_mape',
              'Sales MAPE',
              sales_rep_mape
          )

          const salesRepFvaRow = getFvaRow(
              'fva_sales_rep_mape',
              'Fva Planner',
              fva_sales_rep_mape
          )

          const plannerFvaRow = getFvaRow(
              'fva_planner_mape',
              'Fva Sales',
              fva_planner_mape
          )

          setFvaData((prevData) => ({
            ...prevData,
            actualRow ,
            statForecastRow,
            plannerForecastRow,
            salesRepForecastRow,
            statMapeRow,
            plannerMapeRow,
            salesRepMapeRow,
            salesRepFvaRow,
            plannerFvaRow,
            dataLoaded: true,
          }));
        }
      },
      [
        treeDataState.treeDataResponse,
        horizonRange,
        lagSelection,
      ],
  );

  useEffect(() => {
    if (!hasLoaded && horizonRange.length && treeDataState.treeDataResponse.length) {
      if(LocalStorage.getTreeSelection() !== null && LocalStorage.getSelectedTreeKey() !== null){
        // @ts-ignore
        fetchData(JSON.parse(LocalStorage.getTreeSelection()))
      } else {
        fetchData()
      }
    }
  }, [
    horizonRange.length,
    hasLoaded,
    fetchData,
    treeDataState.treeDataResponse,
  ]);

  const onSelect = (keys: Key[], info: TAny) => {
    setSelectedKeys((prev) => ({ ...prev, reportKeys: keys }))

    const payload = getFetchDataTreePayload(
        info.node,
        treeDataState.treeDataResponse
    )

    LocalStorage.setTreeSelection(JSON.stringify(payload))
    LocalStorage.setSelectedTreeKey(JSON.stringify(keys))

    fetchData(payload)
  }

  useEffect(() => {
    if (fvaData.dataLoaded) {
      const data = [] as RowType[];
      if (fvaData.actualRow) {
        data.push(fvaData.actualRow);
      }

      if (fvaData.statForecastRow) {
        data.push(fvaData.statForecastRow);
      }

      if (fvaData.plannerForecastRow) {
        data.push(fvaData.plannerForecastRow);
      }

      if (fvaData.salesRepForecastRow) {
        data.push(fvaData.salesRepForecastRow);
      }

      if (fvaData.statMapeRow) {
        data.push(fvaData.statMapeRow);
      }

      if (fvaData.plannerMapeRow) {
        data.push(fvaData.plannerMapeRow);
      }

      if (fvaData.salesRepMapeRow) {
        data.push(fvaData.salesRepMapeRow);
      }

      if (fvaData.salesRepFvaRow) {
        data.push(fvaData.salesRepFvaRow);
      }

      if (fvaData.plannerFvaRow) {
        data.push(fvaData.plannerFvaRow);
      }

      setFva((preValue) => {
        return {
          ...preValue,
          data,
          historyLoaded: true,
          loading      : false,
          hasLoaded    : true,
        };
      });
    }
  }, [fvaData,  setFva, lag, lagSelection]);


  const handleLagSelection = () => {
    if (horizonRange.length && treeDataState.treeDataResponse.length) {
      if(LocalStorage.getTreeSelection() !== null && LocalStorage.getSelectedTreeKey() !== null){
        // @ts-ignore
        fetchData(JSON.parse(LocalStorage.getTreeSelection()))
      } else {
        fetchData()
      }
    }
  }

  const onOpenChange = (open: boolean) => {
    if (horizonRange.length && treeDataState.treeDataResponse.length) {
      if (!open) {
        if(LocalStorage.getTreeSelection() !== null && LocalStorage.getSelectedTreeKey() !== null){
          // @ts-ignore
          fetchData(JSON.parse(LocalStorage.getTreeSelection()))
        } else {
          fetchData()
        }
      }
    }
  }

  return (
      <Scaffold selectedKeys={reportKeys} onSelect={onSelect}>
        {(hasLoaded && treeDataState.treeData.length === 0) && (
            <Layout>
              <div className="p-2 md:p-4 pb-16 overflow-y-auto w-full">
                <Result
                    status="404"
                    title='Empty Data'
                    subTitle="Sorry, it seems there is no data found. Please try again later..."
                    extra={
                      <Link to="/" type="primary">
                        Refresh Page
                      </Link>
                    }
                />
              </div>
            </Layout>
        ) || (
          <div ref={printRef}>
            <Layout>
              {loading && (
                  <Layout>
                    <Layout style={{
                      display: 'flex',
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between"
                    }}>
                      <Skeleton.Input style={{width: 175, borderRadius: 8}} active size="small"/>
                      <div style={{display: 'flex', gap: 2}}>
                        <div style={{display: 'flex', flexDirection: "column", gap: 2}}>
                          <Skeleton.Input style={{width: 100, borderRadius: 8}} active size="small"/>
                          <Skeleton.Input style={{width: 50, borderRadius: 8}} active size="large"/>
                        </div>
                        <div style={{display: 'flex', flexDirection: "column", gap: 2}}>
                          <Skeleton.Input style={{width: 100, borderRadius: 8}} active size="small"/>
                          <Skeleton.Input style={{width: 230, borderRadius: 8}} active size="large"/>
                        </div>
                      </div>
                    </Layout>
                  </Layout>
              ) || (
                    <Layout style={{
                      display: 'flex',
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between"
                    }}>
                      <h1 className="text-lg font-bold letter-28 text-black">
                        FORECAST VALUE ADDED
                      </h1>
                      <Space size="large">
                        <LagSelection onChange={handleLagSelection} />
                        <ForecastHorizon
                          value={[ moment(horizonRange[0]), moment(horizonRange[1])]}
                          onOpenChange={onOpenChange}
                        />
                      </Space>
                    </Layout>
              )}
              <ForecastFva data={responseBody}/>
            </Layout>
          </div>
        )}
      </Scaffold>
  );
};

export default Fva;
