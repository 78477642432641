import React, { FC } from 'react';

import { Select } from "antd";
import { useRecoilState } from 'recoil';
import DropdownArrow from 'app/__portions/_drawables/DropdownArrow';
import { FvaDataState, LagSelectionState } from "app/bloc/atoms";
// import DropdownArrow from "../../_drawables/DropdownArrow";
import { LAG_SELECTION } from "../../../utils/helpers/constants";

interface Props {
  onChange?: (() => void) | undefined;
}

const LagSelection: FC<Props> = () => {
  const [lagSelection, setLagSelection] = useRecoilState(LagSelectionState)
  const [, setFva] = useRecoilState(FvaDataState)

  const onLagChange = (value: number) => {
    setLagSelection(value);
    setFva((val) => {
      return ({ ...val, hasLoaded: false })
    });
  };

  return (
    <div>
      <div className="text-xs mb-1 text-black">
        Lag selection
      </div>
      <Select
        size='large'
        className='border-none rounded-full bg-gray'
        // @ts-ignore
        suffixIcon={DropdownArrow}
        defaultValue={lagSelection}
        onChange={onLagChange}
        options={LAG_SELECTION.map(size => ({ label: size, value: size }))}
      />
    </div>
  );
};

LagSelection.defaultProps = {
  onChange: () => null,
};

export default LagSelection;
