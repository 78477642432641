import {
  Dropdown,
  Menu,
  Modal,
  Progress,
  Tag,
  Upload,
  UploadProps,
  message,
} from 'antd';

import { useState } from 'react';
import { UploadFile } from 'antd/lib/upload/interface';
import {
  CloseCircleOutlined,
} from '@ant-design/icons';
import { HiOutlineUpload } from 'react-icons/hi';
import ENDPOINTS from 'app/Services/endpoints';
import Api from 'app/Services';

import DropdownArrow from 'app/__portions/_drawables/DropdownArrow';
import LocalStorage from 'app/utils/helpers/LocalStorage';
import { findDuplicates, removeDuplicatesWithLastItemKept } from "app/utils/helpers";
import { getPayload } from 'app/utils/helpers/excelFileParser';
import { IDuplicate, IFileStatus, IPayloadProps, TAny } from "../../../typings";

const UploadDropdown = ({ showTitle = false }) => {
  const [duplicates , setDuplicates ] = useState<IDuplicate>({items: [], options:{}});
  const [payloadData, setPayloadData] = useState<IPayloadProps[]>([]);
  const [fileStatus , setFileStatus ] = useState<IFileStatus>({
    isUploading: false,
    percent: 0,
    status: 'normal',
  });

  const handleProceed = async () => {
    const { onSuccess, onError, onProgress, filename } = duplicates.options;
    const payload = removeDuplicatesWithLastItemKept(payloadData)

    try {
      setDuplicates((prev) => ({
        ...prev,
        items: [],
      }));
      const config = {
        headers: {
          Authorization: `Bearer ${LocalStorage.getAccessToken()}`,
        },
        onUploadProgress: (event: TAny) => {
          const percent = Math.floor(
            (event.loaded / event.total) * 100,
          );

          if (percent > 95) {
            onProgress({ percent: 95 });
          } else {
            onProgress({ percent });
          }
        },
      };

      const res = await Api.post(
        `${ENDPOINTS.UPLOAD}${filename.toLowerCase()}`,
        payload,
        config,
      );

      onProgress({ percent: 100 });
      if (res.data) {
        onSuccess('Ok');
        setFileStatus((prev) => ({
          ...prev,
          isUploading: false,
        }));
        return;
      }
      if (res.error) {
        setFileStatus((prev) => ({
          ...prev,
          isUploading: false,
        }));
        onError(res);
      }
    } catch (error) {
      setFileStatus((prev) => ({
        ...prev,
        isUploading: false,
      }));
      console.error(`=== CATCH ERROR === ${error}`)
      onError(error);
    }
  }

  const handleCancel = () => {
    setFileStatus((prev) => ({
      ...prev,
      isUploading: false,
    }));
    setDuplicates((prev) => ({
      ...prev,
      items: [],
    }));
  }

  const uploadData = async (options: TAny) => {
    const { onSuccess, onError, file, onProgress, filename } = options;
    try {
      const payload = await getPayload(file, file.name, filename);
      // @ts-ignore
      setPayloadData(payload)
      // @ts-ignore
      const duplicates = findDuplicates(payload)

      if (duplicates.length > 0) {
        setDuplicates((prev) => ({
          ...prev,
          items: duplicates,
          options
        }));

        return
      }

      const config = {
        headers: {
          Authorization: `Bearer ${LocalStorage.getAccessToken()}`,
        },
        onUploadProgress: (event: TAny) => {
          const percent = Math.floor(
            (event.loaded / event.total) * 100,
          );

          if (percent > 95) {
            onProgress({ percent: 95 });
          } else {
            onProgress({ percent });
          }
        },
      };

      const res = await Api.post(
        `${ENDPOINTS.UPLOAD}${filename.toLowerCase()}`,
        payload,
        config,
      );

      onProgress({ percent: 100 });
      if (res.data) {
        onSuccess('Ok');
        return;
      }
      if (res.error) {
        onError(res);
      }
    } catch (error) {
      console.error(`=== CATCH ERROR === ${error}`)
      onError(error);
    }
  };

  const ACCEPTED_FILE_TYPE =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

  const beforeUpload = (file: UploadFile) => {
    const isSupported = ACCEPTED_FILE_TYPE.includes(
      file.type as string,
    );
    if (!isSupported) {
      message.error(
        `You can only upload ${ACCEPTED_FILE_TYPE} file!`,
      );
    }
    const isLt5M = (file.size as number) / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.error('File must be smaller than 5MB!');
    }
    return isSupported && isLt5M;
  };

  const props: UploadProps = {
    name: 'file',
    customRequest: uploadData,
    accept: ACCEPTED_FILE_TYPE,
    beforeUpload,
    onChange(info) {
      setFileStatus((fileStatus) => ({
        ...fileStatus,
        percent: info.file.percent as number,
      }));

      if (info.file.status === 'uploading') {
        setFileStatus((fileStatus) => ({
          ...fileStatus,
          isUploading: true,
        }));
      }

      if (info.file.status === 'done') {
        setFileStatus((fileStatus) => ({
          ...fileStatus,
          isUploading: false,
          status: 'success',
        }));
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        setFileStatus((fileStatus) => ({
          ...fileStatus,
          isUploading: false,
          status: 'exception',
        }));
        if (info.file.error) {
          message.error(info.file.error.message);
        } else {
          message.error(`${info.file.name} file upload failed.`);
        }
      }
    },
  };

  const salesProps: UploadProps = {
    ...props,
    maxCount: 10,
    multiple: true,
    listType: "picture",
    name: 'actual',
  };

  const forecastsProps: UploadProps = {
    ...props,
    maxCount: 10,
    multiple: true,
    listType: "picture",
    name: 'forecast',
  };

  const budgetProps: UploadProps = {
    ...props,
    name: 'budget',
  };

  const menu = (
    <Menu className="border rounded-md">
      <Menu.Item key="actuals">
        <Upload {...salesProps}>Upload Actuals</Upload>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="forecast">
        <Upload {...forecastsProps}>Upload Forecasts</Upload>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="budget">
        <Upload {...budgetProps}>Upload Budget</Upload>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={menu} className="pointer p-2">
        <div key="upload" className="ant-row ant-space-align-center ant-row-no-wrap hover:text-blue ml-0">
          {(!fileStatus.isUploading && (
            <HiOutlineUpload
              size="16px"
              className="flex-shrink-0"
              style={{ marginRight: 4 }}
            />
          )) || (
            <Progress
              type="circle"
              percent={fileStatus.percent}
              width={20}
              status={fileStatus.status}
            />
          )}
          <div className="ant-row ant-space-align-center ant-row-no-wrap ant-row-space-between w-full">
            {showTitle && <div className="px-1">Upload</div>}
            <DropdownArrow />
          </div>
        </div>
      </Dropdown>
      <Modal
        title={`${duplicates.items.length} duplicated item(s) found`}
        open={duplicates.items.length > 0}
        okText="Proceed"
        onOk={handleProceed}
        onCancel={handleCancel}
      >
        {(
          duplicates.items.map((dup, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <p key={`${dup.item}${i}`}>
              <Tag icon={<CloseCircleOutlined />} color="error">error</Tag>
              {dup.item}
            </p>
          ))
        )}
      </Modal>
    </>
  );
};

export default UploadDropdown;
