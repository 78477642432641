import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Button, Popover, Spin, Table } from "antd";
import { BiImport } from 'react-icons/bi';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import { IAdjustments, RowType, TAny } from 'app/typings';
import {
  EditableCell,
  EditableRow,
} from 'app/__portions/EditableRow';
import { isRole } from 'app/utils/configs';
import { USER_ROLES } from 'app/utils/helpers/constants';

type Props = {
  columns?: RowType[];
  data?: TAny[];
  loading?: boolean;
  saving?: boolean;
  isPastCycle?: boolean;
  onSave?: (adjustments: IAdjustments) => void;
  onPreviousCycle?: () => void;
};

const DemandPlanningTable = ({
  columns,
  data,
  loading,
  saving,
  isPastCycle,
  onSave,
  onPreviousCycle,
}: Props) => {
  const [dataSource, setDataSource] = useState(data);
  const tableRef = useRef<HTMLDivElement>(null);

  const disabled = isPastCycle || loading || saving;
  const isAdmin = isRole(USER_ROLES.Admin.value);

  const tableColumns: RowType[] = [
    {
      width: 250,
      dataIndex: 'row_title',
      key: 'row_title',
      fixed: 'left',
    },
    ...(columns || []),
  ];

  useEffect(() => {
    setDataSource(data);

    const element = document.querySelector('.currentDay');
    element?.scrollIntoView({ block: 'nearest', inline: 'center' });
  }, [data]);

  const handleSave = useCallback((row) => {
      const newData = [...(dataSource || [])];
      const index = newData.findIndex((item) => row.key === item.key);
      const item = newData[index];

      newData.splice(index, 1, {
        ...item,
        ...row,
      });

      const { key } = row;
      delete row.key;
      const adjustments = { [key]: { ...row } };
      onSave?.(adjustments as TAny);
      setDataSource(newData);
    },
    [dataSource, onSave],
  );

  const editableInputCell = useCallback(
    (record: TAny, col: RowType) => {
      const isEditable = record?.editable && col.editable;

      return {
        record,
        editable: isEditable,
        dataIndex: col.dataIndex,
        title: col.title,
        requireMessage: 'Adjustment',
        className: 'editable-row',
        handleSave,
        loading,
      };
    },
    [handleSave, loading],
  );

  const cols = tableColumns.map((col) => {
    if (!col.editable) {
      return {
        ...col,
        onCell: (record: TAny) => ({
          record,
          className: 'extend-padding font-medium',
        }),
      };
    }
    return {
      ...col,
      onCell: (record: TAny) => editableInputCell(record, col),
      render: (forecast: TAny, record: TAny) => {
        return (
          <div
            className={record.editable ? 'px-2' : ''}
            style={{ whiteSpace: 'nowrap' }}
          >
            {forecast?.prevCycle && (
              <Popover
                content={
                  <div className="pb-3">
                    Imported from <b>{forecast.prevCycle}</b> cycle
                  </div>
                }
              >
                <HiOutlineInformationCircle className="text-blue absolute left-0 w-[24px] h-[24px]"/>
              </Popover>
            )}
            {forecast?.value || forecast}
          </div>
        );
      },
    };
  });

  const components = useRef({
    body: { cell: EditableCell, row: EditableRow },
  });

  return (
    <>
      {!loading && !isAdmin && (
        <div className="flex justify-end mb-4">
          <Button
            icon={<BiImport />}
            className="border-w-2 rounded-sm mr-2 h-42 items-center"
            disabled={disabled}
            onClick={onPreviousCycle}
          >
            <span className="ml-2">Previous cycle</span>
          </Button>
        </div>
      )}
      <Table
        ref={tableRef}
        columns={cols as TAny}
        components={components.current}
        dataSource={dataSource}
        pagination={false}
        scroll={{ x: 'max-content' }}
        loading={loading}
        onRow={(record) => {
          return {
            className: record.editable ? 'bg-gray-200' : '',
          };
        }}
      />
      {!loading && (
        <div className="flex justify-between my-2">
          <p className="text-xs text-black-50">
            Press ESC to dismiss the adjustment input box
          </p>
          <Spin
            tip="Saving adjustments..."
            size="small"
            className="custom-spinner"
            spinning={saving}
          />
        </div>
      )}
    </>
  );
};

DemandPlanningTable.defaultProps = {
  columns: [],
  data: [],
  loading: false,
  saving: false,
  isPastCycle: false,
  onSave: () => null,
  onPreviousCycle: () => null,
};

export default DemandPlanningTable;
