import React, { CSSProperties } from 'react';
import { Select } from 'antd';
import { TAny } from 'app/typings';
import DropdownArrow from '../_drawables/DropdownArrow';

const { Option } = Select;

type Props = {
  options?:
    | { name: string; value: string }[]
    | Record<TAny, { name: string; value: string }>
    | TAny[];
  defaultValue?: TAny;
  className?: string;
  placeholder?: string | null;
  bordered?: boolean;
  disabled?: boolean;
  style?: CSSProperties;
  onChange?: (value?: TAny) => void;
  children?: React.ReactNode | React.ReactNode[]
};

const SelectInput = ({
  options,
  defaultValue,
  className,
  placeholder,
  bordered,
  disabled,
  style,
  children,
  onChange,
}:Props) => {
  return (
    <Select
      defaultValue={defaultValue}
      bordered={bordered}
      style={style}
      className={className}
      disabled={disabled}
      placeholder={placeholder}
      // @ts-ignore
      suffixIcon={DropdownArrow}
      onChange={onChange}
    >
      {children ||
        Object.values(options || {}).map(({ value, name }) => (
          <Option key={value} value={value}>
            {name}
          </Option>
        ))}
    </Select>
  );
};

SelectInput.defaultProps = {
  defaultValue: null,
  children: null,
  className: '',
  placeholder: null,
  bordered: true,
  disabled: false,
  options: {},
  style: {},
  onChange: () => null,
};

export default SelectInput;
