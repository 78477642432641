import {ElementType, Key, ReactNode} from "react";

export default interface IRoute {
  path: string | string[];
  name: string;
  exact: boolean;
  secured?: boolean;
  roles?: string[];
  component: ElementType;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TAny = any;

export interface ITree {
  title: Element;
  key: string;
  name: string;
  icon?: ReactNode;
  selected?: boolean;
  switcherIcon?: ReactNode;
  children?: ITree[];
}

export interface INode {
  title: string;
  region: string;
  key: string;
  type: string;
  name: string;
}

export interface RowType {
  key: string;
  [key: string]:
    | string
    | boolean
    | number
    | Record<string, string | number | boolean>
    | JSX.Element
    | (() => void)
    | RowType[];
}
export interface IErrorState {
  aggregationError?: { error: string | null; message: string | null };
  isServerDown?: boolean;
}

export type TSupportedKeys =
  | 'reportKeys'
  | 'analyticsKeys'
  | 'planningKeys'
  | 'capacityKeys';

export type ISelectedKeys = { [key in TSupportedKeys]: Key[]; };

export interface IDataset {
  type: 'line' | 'bar';
  label: string;
  fill?: boolean;
  pointRadius?: number;
  data: (Record<string, number> | string)[];
  backgroundColor?: string;
  borderColor?: string;
}

export interface IGraphData {
  datasets: IDataset[];
}

export interface IChartLine {
  labels?: string[];
  datasets: {
    label: string;
    data: Record<string, number> | number[];
    borderColor?: string;
    backgroundColor?: string;
  }[];
}

export interface IAnalytics {
  hasLoaded: boolean;
  currentYear: string;
  fiscalData: { [key: string]: number | string }[];
  projectionData: { [key: string]: number | string }[];
  graphAnalytics: IGraphData;
}

export const dailyHorizon   = [15, 30, 60, 90]
export const weeklyHorizon  = [ 4,  6,  8, 12]
export const monthlyHorizon = [ 3,  6, 12, 18]

export type TGranularityDateFormatDisplay = 'YYYY-MMM-DD' | 'MMM-DD' | 'YYYY-MMM';

export type TGranularityDateFormat = 'YYYY-MM-DD' | 'MM-DD' | 'YYYY-MM';

export type TGranularityTypeDay = 'day' | 'week' | 'month';
export type TGranularityType = 'date' | 'week' | 'month';
export type TGranularitySelectionType = 'daily' | 'weekly' | 'monthly';

export type TForecastSelectionType = 'statistical_forecast' | 'external_forecast';
export type TForecastTableType = 'over_items' | 'over_history';

export type TErrorMeasure = 'RMSE' | 'MAPE' | 'wMAPE';

export type TPredictionModel =
  | 'MA'
  | 'ARIMA'
  | 'SARIMA'
  | 'ES'
  | 'SES'
  | 'HWA'
  | 'HWM'
  | 'C-TSP';

export interface IModel {
  accuracy: number;
  description: string;
  forecast: Record<string, number>;
  name: TPredictionModel;
  RMSE: number;
}

export interface ICycle {
  date: string;
  done: boolean;
}
export interface IDemandResponse {
  actual: Record<string, number>;
  actual_adj: Record<string, number>;
  planner_adj: Record<string, number>;
  sales_adj: Record<string, number>;
  external_forecast: Record<string, number>;
  planner_consensus: IModel[];
  sales_consensus: IModel[];
  statistical: IModel;
  cycle: ICycle;
  best_model: TPredictionModel;
  model: IModel;
  models: IModel[];
}

export interface IAdjustments {
  actualAdjts: Record<string, number>;
  plannerAdjts: Record<string, number>;
  salesAdjts: Record<string, number>;
}

export interface IConsensus {
  salesConsensus: IModel[];
  plannerConsensus: IModel[];
}

export interface IDemandPlanning {
  loading?: boolean;
  hasLoaded: boolean;
  horizon: number;
  currentCycle: string;
  performance: string;
  data: {
    columns?: {
      title: string;
      dataIndex: string;
      key: string;
      editable: boolean;
    }[];
    rowData?: {
      key: string;
      row_title: string;
    }[];
    actual?: Record<string, number>;
    externalForecast?: Record<string, number>;
    adjustments: IAdjustments;
    consensus: IConsensus;
    graphData: IChartLine;
    accuracy?: number;
    metric?: TErrorMeasure;
    model?: IModel;
    cycle?: ICycle;
    statistical?: IModel;
    models?: IModel[];
  };
}

export interface IReportTableData {
  actualRow   : RowType;
  forecastRows: RowType[];
  historyRows : RowType[];
  leadRow    ?: RowType;
  metricRows ?: RowType[];
  dataLoaded  : boolean;
  forecastEvol: object;
}

export interface IFvaTableData {
  actualRow          : RowType;
  statForecastRow    : RowType;
  plannerForecastRow : RowType;
  salesRepForecastRow: RowType;
  statMapeRow        : RowType;
  plannerMapeRow     : RowType;
  salesRepMapeRow    : RowType;
  salesRepFvaRow     : RowType;
  plannerFvaRow      : RowType;
  dataLoaded         : boolean;
}

export interface IUserTableData {
  key?: string;
  user_id?: number;
  info: {
    firstName: string;
    lastName: string;
    avatarUrl?: string;
  };
  role: string;
  email: string;
  location?: string;
  phone?: string;
}

export interface IUserData {
  id?: number;
  first_name: string;
  last_name: string;
  username: string;
  profile_image?: string;
  role: string;
  email: string;
  is_staff?: string;
}

export interface IMutateUserState {
  currentUser: Partial<IUserTableData>;
  isCreate: boolean;
  isEdit: boolean;
  hasLoaded: boolean;
  loading: boolean;
  showDetails: boolean;
  users: IUserTableData[];
}

export interface IUserState {
  currentUser: Partial<IUserData>;
  isEdit: boolean;
  hasLoaded: boolean;
  loading: boolean;
  showDetails: boolean;
}

export type TPerformanceCheckers = 'Planner' | 'Sales' | 'Machine';

export type TUserRoles = 'Admin' | 'Planner' | 'Sales';

export type TCapacity = {
  items: number;
  months: {
    title: string;
    load: number;
  }[];
  weeks: {
    title: string;
    load: number;
    months: string[];
  }[];
  days: {
    title: string;
    load: number;
    week: string;
    month: string;
    day: string;
  }[];
};
export interface ICapacityDemand {
  dateFilter: [string, string];
  currentChart: number;
  capacities: TCapacity;
  loading: boolean;
  hasLoaded: boolean;
  unit: number;
}

export type INotificationType = 'AC' | 'FO' | 'BU';
export type INotificationStatus = 'PR' | 'PA' | 'CU';

export interface INotification {
  id         : string;
  file_name  : string;
  upload_type: INotificationType;
  status     : INotificationStatus;
  created_at : string;
}
export interface INotificationResponse {
  loading: boolean,
  items: number,
  data?: INotification[];
}

export interface IHorizon {
  value: number;
  name?: string;
}

export interface IHorizonData {
  loading: boolean;
  selected: IHorizon;
  horizon: IHorizon[];
}

export interface IResponseAccuracyData {
  actual  : { [key: string]: number; };
  forecast: { [key: string]: number; };
  leads   : { [key: string]: number; };
  metrics: {
    MAE  : { [key: string]: string; };
    BIAS : { [key: string]: string; };
    RMSE : { [key: string]: string; };
    MAPE : { [key: string]: string; };
    wMAPE: { [key: string]: string; };
  };
}

export interface IResponseFvaData {
  actual               : { [key: string]: number; };
  statistical_forecast : { [key: string]: number; };
  planner_forecast     : { [key: string]: number; };
  sales_rep_forecast   : { [key: string]: number; };
  statistical_mape     : { [key: string]: string; };
  planner_mape         : { [key: string]: string; };
  sales_rep_mape       : { [key: string]: string; };
  fva_sales_rep_mape   : { [key: string]: string; };
  fva_planner_mape     : { [key: string]: string; };
}

export interface IResponseWaterfallData {
  actual: { [key: string]: number; };
  forecast: { [key: string]: { [key: string]: number; }; };
  leads: { [key: string]: number; };
  metrics: {
    MAE: { [key: string]: string; };
    BIAS: { [key: string]: string; };
    RMSE: { [key: string]: string; };
    MAPE: { [key: string]: string; };
    wMAPE: { [key: string]: string; };
  };
}

export type IPayloadProps = {
  made_on  : unknown,
  line     : string,
  region   : string,
  group    : string,
  category : string,
  item     : string,
  reference: string,
  data     : unknown
}

export type IFileStatus = {
  isUploading: boolean;
  percent    : number;
  status     : 'normal' | 'success' | 'exception' | 'active';
}

export type IDuplicate = {
  items   : IPayloadProps[];
  options : TAny;
}