import moment from 'moment';
import {Key, useCallback, useEffect, useState} from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { message } from 'antd';
import {
  DemandPlanningState, ErrorsState,
  selectedKeysState,
  SelectedNode,
  TreeDataState
} from "app/bloc/atoms";
import Api from 'app/Services';
import ENDPOINTS from 'app/Services/endpoints';
import {
  IAdjustments,
  IChartLine,
  IConsensus,
  ICycle,
  IDemandResponse,
  IModel,
  TAny,
} from "app/typings";
import { isRole } from 'app/utils/configs';
import {
  getFetchDataTreePayload, getGranularityDateFormat, getGranularityDateFormatDisplay, getGranularityDay,
  getPreviousDay, getSelectedGranularityValue,
  getSelectedNode, handleHorizonSelection,
  isEmpty,
  sortByDateAfter,
  toAdjPayload
} from "app/utils/helpers";
import {
  USER_ROLES
} from "app/utils/helpers/constants";
import LocalStorage from "../utils/helpers/LocalStorage";
import {groupByMonth, groupByWeek} from "../pages/Settings/Utils/WeekDataConverter";

const useDemand = () => {
  const setErrorState = useSetRecoilState(ErrorsState);
  const [
    {
      loading,
      hasLoaded,
      currentCycle,
      horizon: forecastHorizon,
      data,
    },
    setDemandPlanning,
  ] = useRecoilState(DemandPlanningState);

  const { treeDataResponse, treeData } = useRecoilValue(TreeDataState);

  const [{ planningKeys }, setSelectedKeys] = useRecoilState(selectedKeysState);
  const [{ planningKeys: selectedNode }, setSelectedNode] = useRecoilState(SelectedNode);

  const [saving, setSaving] = useState(false);

  const isSales = isRole();
  const isPlanner = isRole(USER_ROLES.Planner.value);

  const getGranularityData = (data = {}) => {
    let forecast:TAny;
    let dataRow :TAny;
    let valueRow:TAny;

    switch (getSelectedGranularityValue()) {
      case "daily":
        forecast = data
        break;
      case "weekly":
        dataRow = groupByWeek(data)
        valueRow = dataRow.reduce((acc: TAny, item: TAny) => {
          const key = Object.keys(item)[0];
          acc[key] = item[key];
          return acc;
        }, {});

        forecast = valueRow
        break;
      case "monthly":
        dataRow = groupByMonth({data})
        valueRow = dataRow.reduce((acc: TAny, item: TAny) => {
          const key = Object.keys(item)[0];
          acc[key] = item[key];
          return acc;
        }, {});

        forecast = valueRow
        break;
      default: forecast = data
    }

    return forecast
  }

  const getGraphData = (actual = {}, forecast = {}) => {
    const startDay = moment().startOf(getGranularityDay());

    const forecastData = Object.keys(forecast).reduce((obj, key) => {
      if (moment(key, getGranularityDateFormatDisplay()).isSameOrAfter(moment().day() - 1)) {
        obj[key] = forecast[key];
      }
      return obj;
    }, {});

    const modelData = Object.keys(forecast).reduce((obj, key) => {
      if (moment(key, getGranularityDateFormatDisplay()).isBefore(startDay)) {
        obj[key] = forecast[key];
      }
      return obj;
    }, {});

    let datasets = [
      {
        label: 'Actual',
        data: sortByDateAfter(getGranularityData(actual)),
        borderColor: '#219ebc',
        backgroundColor: '#219ebc',
      },
      {
        label: 'Model',
        data: sortByDateAfter(getGranularityData(modelData)),
        borderColor: '#023047',
        backgroundColor: '#023047',
      },
      {
        label: 'Forecast',
        data: sortByDateAfter(getGranularityData(forecastData)),
        borderColor: '#ffb703',
        backgroundColor: '#ffb703',
      },
    ];

    if (isSales) {
      datasets = datasets.filter(
        (dataset) => dataset.label !== 'Model',
      );
    }

    const graphData: IChartLine = { datasets };

    return graphData;
  };

  const getLines = () => {
    if(LocalStorage.getTreeSelection() !== null && LocalStorage.getSelectedTreeKey() !== null){
      // @ts-ignore
      return JSON.parse(LocalStorage.getTreeSelection())
    }
    return getFetchDataTreePayload(selectedNode, treeDataResponse)
  }

  const getDataByGranularity = (data) => {
    if(!data) return

    let response:object
    let dataRow :TAny
    let valueRow:TAny

    switch (getSelectedGranularityValue()) {
      case "daily":
        response = data
        break;
      case "weekly":
        dataRow  = groupByWeek(data)
        valueRow = dataRow.reduce((acc: TAny, item: TAny) => {
          const key = Object.keys(item)[0];
          acc[key] = item[key];
          return acc;
        }, {});

        response = valueRow
        break;
      case "monthly":
        dataRow  = groupByMonth({ data })
        valueRow = dataRow.reduce((acc: TAny, item: TAny) => {
          const key = Object.keys(item)[0];
          acc[key] = item[key];
          return acc;
        }, {});

        response = valueRow
        break;
      default: response = data
    }

    // eslint-disable-next-line consistent-return
    return response
  }

  const onRefreshData = (
    actual,
    externalForecast,
    model: IModel,
    adjustments,
    consensus: IConsensus,
    models: IModel[],
    cycle?: ICycle,
    cycleForecast?: string,
    isItemNode?: boolean,
  ) => {
    const { actualAdjts, plannerAdjts, salesAdjts } = adjustments;
    const isPastCycle = moment(cycleForecast).isBefore(
      moment().format(getGranularityDateFormat()),
    );

    const plannerConsensus = consensus.plannerConsensus?.find(
      (cons) => cons.name === model.name,
    );
    const salesConsensus = consensus.salesConsensus?.find(
      (cons) => cons.name === model.name,
    );

    const consensusData = isSales ? salesConsensus?.forecast : plannerConsensus?.forecast;

    if (isItemNode === undefined) {
      isItemNode = getSelectedNode(treeData).type === 'item';
    }

    const actualData           = getDataByGranularity(actual)
    const actualAdjtsData      = getDataByGranularity(actualAdjts)
    const forecastData         = getDataByGranularity(model?.forecast)
    const salesAdjtsData       = getDataByGranularity(salesAdjts)
    const plannerAdjtsData     = getDataByGranularity(plannerAdjts)
    const consensusDatas       = getDataByGranularity(consensusData)
    const externalForecastData = getDataByGranularity(externalForecast)

    let rowData = [
      {
        key: 'actualSales',
        row_title: 'Actual sales',
        ...actualData,
      },
      {
        key: 'adjustment',
        row_title: 'Actual Sales adjustments',
        editable: !isPastCycle && isPlanner && isItemNode,
        ...actualAdjtsData,
      },
      {
        key: 'statisticalForecast',
        row_title: 'Statistical forecast',
        ...forecastData,
      },
      {
        key: 'salesAdjts',
        row_title: 'Forecast adjustments (Sales Rep)',
        editable: !isPastCycle && isSales && isItemNode,
        ...salesAdjtsData,
      },
      {
        key: 'plannerAdjts',
        row_title: 'Forecast adjustments (Planner)',
        editable: !isPastCycle && isPlanner && isItemNode,
        ...plannerAdjtsData,
      },
      {
        key: 'consensus',
        row_title: 'Consensus Demand',
        ...consensusDatas,
      },
      {
        key: 'externalForecast',
        row_title: 'External Forecast',
        ...externalForecastData,
      },
    ];

    if (isSales) {
      rowData = rowData.filter((row) => row.key !== 'plannerAdjts' && row.key !== 'adjustment');
    }

    let columns:TAny
    let actualDataRow:TAny
    let actualValueRow:TAny

    let forecastDataRow:TAny
    let forecastValueRow:TAny

    switch (getSelectedGranularityValue()) {
      case "daily":
         columns = Object.keys({ ...actual, ...model?.forecast })
            .sort((a, b) => moment(a, getGranularityDateFormatDisplay()).isAfter(moment(b, getGranularityDateFormatDisplay())) ? 1 : -1)
            .map((key) => ({
              title: key,
              dataIndex: key,
              key: `header_${key}`,
              editable: true,
              onHeaderCell: (record:TAny) => ({
                className: moment(record.dataIndex, getGranularityDateFormatDisplay()).isSame(moment().format(getGranularityDateFormatDisplay())) ? 'currentDay' : '',
              }),
            }))
        break;
      case "weekly":
        actualDataRow = groupByWeek(actual)
        actualValueRow = actualDataRow.reduce((acc: TAny, item: TAny) => {
          const key = Object.keys(item)[0];
          acc[key] = item[key];
          return acc;
        }, {});

        forecastDataRow = groupByWeek(model?.forecast)
        forecastValueRow = forecastDataRow.reduce((acc: TAny, item: TAny) => {
          const key = Object.keys(item)[0];
          acc[key] = item[key];
          return acc;
        }, {});

        columns = Object.keys({ ...actualValueRow, ...forecastValueRow })
            .sort((a, b) => moment(a, getGranularityDateFormatDisplay()).isAfter(moment(b, getGranularityDateFormatDisplay())) ? 1 : -1)
            .map((key) => ({
              title: key,
              dataIndex: key,
              key: `header_${key}`,
              editable: true,
              onHeaderCell: (record:TAny) => ({
                className: moment(record.dataIndex, getGranularityDateFormatDisplay()).isSame(moment().format(getGranularityDateFormatDisplay())) ? 'currentDay' : '',
              }),
            }))
        break;
      case "monthly":
        actualDataRow = groupByMonth({ data: actual })
        actualValueRow = actualDataRow.reduce((acc: TAny, item: TAny) => {
          const key = Object.keys(item)[0];
          acc[key] = item[key];
          return acc;
        }, {});

        forecastDataRow = groupByMonth({data: model?.forecast})
        forecastValueRow = forecastDataRow.reduce((acc: TAny, item: TAny) => {
          const key = Object.keys(item)[0];
          acc[key] = item[key];
          return acc;
        }, {});

        columns = Object.keys({ ...actualValueRow, ...forecastValueRow })
            .sort((a, b) => moment(a, getGranularityDateFormatDisplay()).isAfter(moment(b, getGranularityDateFormatDisplay())) ? 1 : -1)
            .map((key) => ({
              title: key,
              dataIndex: key,
              key: `header_${key}`,
              editable: true,
              onHeaderCell: (record:TAny) => ({
                className: moment(record.dataIndex, getGranularityDateFormatDisplay()).isSame(moment().format(getGranularityDateFormatDisplay())) ? 'currentDay' : '',
              }),
            }))
        break;
      default: columns = Object.keys({ ...actual, ...model?.forecast })
          .sort((a, b) => moment(a, getGranularityDateFormatDisplay()).isAfter(moment(b, getGranularityDateFormatDisplay())) ? 1 : -1)
          .map((key) => ({
            title: key,
            dataIndex: key,
            key: `header_${key}`,
            editable: true,
            onHeaderCell: (record) => ({
              className: moment(record.dataIndex, getGranularityDateFormatDisplay()).isSame(moment().format(getGranularityDateFormatDisplay())) ? 'currentDay' : '',
            }),
          }))
    }

    const graphData: IChartLine = getGraphData(actual, consensusData || {});

    setDemandPlanning((state) => ({
      ...state,
      loading: false,
      hasLoaded: true,
      data: {
        actual,
        rowData,
        columns,
        accuracy: model?.accuracy,
        adjustments,
        consensus,
        cycle,
        model,
        models,
        graphData,
      },
    }));
  };

  const removeZeroValues = (data: Record<string, { value: number; message: string }>) => {
    if(!data) return

    // eslint-disable-next-line consistent-return
    return Object.fromEntries(
      Object.entries(data).filter(([, entry]) => entry.value !== 0)
    );
  };

  const getPlanningForecast = useCallback(
    async (
      lines: Record<string, TAny>[] = getLines(),
      horizon = forecastHorizon,
      cycleForecast = currentCycle,
      isItemNode = getSelectedNode(treeData).type === 'item',
      loading = true,
    ) => {
      setDemandPlanning((state) => {
        return { ...state, loading, horizon }
      });

      if (!lines.length) {
        return;
      }

      const payload = {
        lines,
        horizon,
        cycle:  cycleForecast,
      };

      const { data, message, status } = await Api.post(
        ENDPOINTS.PLANNING_FORECAST,
        payload,
      );

      if (status === 400 || status === 404 || status === 500) {
        setErrorState((errors) => ({
          ...errors,
          aggregationError: {error: 'An error occurred', message},
          isServerDown: true,
        }));
        return;
      }

      if (message !== 'success') {
        return;
      }

      setDemandPlanning((prev) => {
        return { ...prev, loading: false }
      });

      const { actual_adj, planner_adj, sales_adj } = data

      const adjActual = removeZeroValues(actual_adj)
      const adjPlanner= removeZeroValues(planner_adj)
      const adjSales  = removeZeroValues(sales_adj)

      const {
        actual,
        external_forecast,
        best_model: bestModel,
        models,
        cycle,
        sales_consensus: salesConsensus,
        planner_consensus: plannerConsensus,
      } = data as IDemandResponse;

      const model = models?.find((model) => model.name === bestModel);

      onRefreshData(
        actual,
        external_forecast,
        model as IModel,
        {
          actualAdjts: adjActual,
          plannerAdjts: adjPlanner,
          salesAdjts: adjSales
        },
        {
          plannerConsensus,
          salesConsensus,
        },
        models,
        cycle,
        cycleForecast,
        isItemNode,
      );
    },
    [setDemandPlanning, treeDataResponse],
  );

  const onSelect = async (keys: Key[], info: TAny) => {
    setSelectedKeys((prev) => ({ ...prev, planningKeys: keys }))

    const payload = getFetchDataTreePayload(
      info.node,
      treeDataResponse
    )

    LocalStorage.setTreeSelection(JSON.stringify(payload))
    LocalStorage.setSelectedTreeKey(JSON.stringify(keys))

    setSelectedNode((prev) => {
      return { ...prev, planningKeys: info.node }
    });

    await getPlanningForecast(
      payload,
      forecastHorizon,
      currentCycle,
      info?.node?.type === 'item',
    );
  };

  const onUpdateForecast = async (
    adjustments,
    loading = true,
    shouldNotify = true,
  ) => {
    setDemandPlanning((val) => ({ ...val, loading }));
    const lines = getLines()

    const payload = {
      lines,
      cycle         : moment(currentCycle).format(getGranularityDateFormat()),
      stats_model   : data.model?.name,
      stats_error   : data.model?.RMSE,
      stats_accuracy: data.model?.accuracy,
      statistical   : toAdjPayload(data.model?.forecast as TAny),
      actual_adj    : (isEmpty(adjustments.actualAdjts)  && toAdjPayload(adjustments.actualAdjts))  || undefined,
      sales_adj     : (isEmpty(adjustments.salesAdjts)   && toAdjPayload(adjustments.salesAdjts))   || undefined,
      planner_adj   : (isEmpty(adjustments.plannerAdjts) && toAdjPayload(adjustments.plannerAdjts)) || undefined,
    };

    const { message: responseMsg, error, status } = await Api.put(
      ENDPOINTS.PLANNING_FORECAST,
      payload,
    );

    if (error) {
      setDemandPlanning((val) => ({ ...val, loading: false }));

      if (shouldNotify) {
        message.error('Failed to update the forecast');
      }

      return;
    }

    if (status === 400 || status === 404 || status === 500) {
      setErrorState((errors) => ({
        ...errors,
        aggregationError: {error: 'An error occurred', message: responseMsg as string},
        isServerDown: true,
      }));

      return;
    }

    if (responseMsg) {
      await getPlanningForecast(
        lines,
        forecastHorizon,
        currentCycle,
        selectedNode.type === 'item',
        false,
      );

      if (shouldNotify) {
        message.success('Forecast was updated successfully');
      }
      setDemandPlanning((val) => ({ ...val, loading: false }));
    }
  };

  const onSave = async (adjustments: IAdjustments) => {
    setSaving(true);
    await onUpdateForecast(adjustments, false, false);
    setSaving(false);
  };

  const onCycleChange = async (cycle: string) => {
    const lines = getLines()

    setDemandPlanning((val) => ({
      ...val,
      currentCycle: cycle,
    }));

    await getPlanningForecast(lines, forecastHorizon, cycle);
  };

  const onHorizonChange = async (horizon: number) => {
    const lines = getLines()

    await getPlanningForecast(lines, handleHorizonSelection(horizon));
  };

  const onPreviousCycle = async () => {
    const lines = getLines()

    setDemandPlanning((val) => ({ ...val, loading: true }));

    const { data: res, error, message: msg, status } = await Api.post(
      ENDPOINTS.PREVIOUS_CYCLE,
      { lines },
    );

    if (error) {
      setDemandPlanning((val) => ({ ...val, loading: false }));
      message.error(
        'Failed to import adjustments from previous cycle',
      );
      return;
    }

    if (status === 400 || status === 404 || status === 500) {
      setErrorState((errors) => ({
        ...errors,
        aggregationError: {error: 'An error occurred', message: msg},
        isServerDown: true,
      }));
      return;
    }

    if (res) {
      const prevAdjData = Object.keys(res).reduce((item, curr) => {
        item[curr] = Object.keys(res[curr]).reduce((obj, key) => {
          obj[key] = { ...res[curr][key], prevCycle: getPreviousDay() }
          return obj
        }, {});

        return item
      }, {}) as TAny

      const rowData = data.rowData?.map((row) => {
        if (row.key === 'adjustment') {
          return { ...prevAdjData.actual_adj, ...row };
        }
        if (row.key === 'salesAdjts') {
          return { ...prevAdjData.sales_adj, ...row };
        }
        if (row.key === 'plannerAdjts') {
          return { ...prevAdjData.planner_adj, ...row };
        }
        return row;
      });

      setDemandPlanning((val) => ({
        ...val,
        data: { ...val.data, rowData },
        loading: false,
      }));
      message.success(
        'Adjustments from previous cycle were successfully imported',
      );
    }
  };

  useEffect(() => {
    if (selectedNode && Object.keys(selectedNode).length === 0) {
      setSelectedNode((nodes) => ({
        ...nodes,
        planningKeys: getSelectedNode(treeData),
      }));
    }
  }, [treeData, selectedNode, setSelectedNode]);

  return {
    saving,
    loading,
    hasLoaded,
    currentCycle,
    data,
    planningKeys,
    forecastHorizon,
    treeDataResponse,
    setDemandPlanning,
    getPlanningForecast,
    onRefreshData,
    onSelect,
    onSave,
    onUpdateForecast,
    onCycleChange,
    onHorizonChange,
    onPreviousCycle,
  };
};

export default useDemand;
