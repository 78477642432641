import React, { useEffect, useMemo, useRef } from 'react';
import { Table } from 'antd';
import moment from 'moment';
import { useRecoilValue } from 'recoil';
import { DAILY_DISPLAY_FORMAT } from 'app/utils/helpers/constants';
import { CapacityState } from 'app/bloc/atoms';

const CapacityTable = () => {
  const { capacities, loading } = useRecoilValue(CapacityState);

  const { months, weeks, days, items } = capacities;
  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!days.length) return;

    const theads = tableRef.current?.querySelectorAll('.col-span');

    tableRef.current
      ?.querySelector('.ant-table-measure-row')
      ?.classList.add('hidden');

    theads?.forEach((thead, index) => {
      // GET THE ODD ITEM -- HIDE THE EVEN ITEMS
      if (index % 2 === 0) {
        const evenThead = theads[index + 1];
        const colSpan = Number(
          thead.attributes.getNamedItem('colspan')?.value || 0,
        );

        if (evenThead) {
          const evenColSpan = Number(
            evenThead.attributes.getNamedItem('colspan')?.value || 0,
          );

          thead.setAttribute(
            'colspan',
            (colSpan + evenColSpan).toString(),
          );
        }
      }
    });
  }, [tableRef, loading, days]);

  const weekCellHeader = (months: string[], month: string) => ({
    className:
      months.length > 1
        ? 'col-span border-r-black'
        : 'border-r-black',
    style: {
      display:
        months.length > 1 && months[months.length - 1] === month
          ? 'none'
          : undefined,
    },
  });

  const columns = useMemo(() => {
    return months.map(({ title: month, load }, monthIndex) => {
      const weeksInMonth = weeks.filter((week) =>
        week?.months?.includes(month),
      );

      return {
        title: (
          <>
            <p className="m-0" key={month}>
              {month}
            </p>
            <p className="m-0">{load}</p>
          </>
        ),
        dataIndex: month,
        onHeaderCell: () => ({
          className:
            monthIndex !== months.length - 1
              ? 'border-r-black'
              : undefined,
        }),
        children: weeksInMonth.map((week) => {
          const monthsInWeeks = [...week.months].sort((a, b) =>
            moment(a, DAILY_DISPLAY_FORMAT).isBefore(
              moment(b, DAILY_DISPLAY_FORMAT),
            )
              ? -1
              : 1,
          );

          const daysInWeek = days.filter(
            (day) => day.week === week.title && day.month === month,
          );

          return {
            title: (
              <>
                <p className="m-0" key={week.title}>
                  {week.title}
                </p>
                <p className="m-0">{week.load}</p>
              </>
            ),
            dataIndex: week.title,
            onHeaderCell: () => weekCellHeader(monthsInWeeks, month),
            children: daysInWeek.map((day) => {
              const isLastDayOfWeek =
                day.title ===
                  daysInWeek[daysInWeek.length - 1].title &&
                monthsInWeeks[monthsInWeeks.length - 1] === day.month;

              return {
                title: (
                  <>
                    <p className="m-0 text-xs text-secondary">
                      {day.day}
                    </p>
                    <p className="m-0">{day.title}</p>
                  </>
                ),
                dataIndex: `${week.title}_${day.title}`,
                onHeaderCell: () => ({
                  className: `th-center ${
                    isLastDayOfWeek ? 'border-r-black' : ''
                  }`,
                }),
                onCell: () => ({
                  className: isLastDayOfWeek ? 'border-r-black' : '',
                }),
              };
            }),
          };
        }),
      };
    });
  }, [months, weeks, days]);

  const dataSource = useMemo(() => {
    if (!days.length) return [];

    return [
      days.reduce((curr, day) => {
        curr[`${day.week}_${day.title}`] = day.load;
        return { ...curr, key: 'daily_amount' };
      }, {}),
    ];
  }, [days]);

  return (
    <div className="w-full">
      <div className="ant-row ant-row-space-between items-center mb-4">
        <div className="text-black-50">
          Unit of measurement:
          <span className="font-medium mx-1">Hours</span>
        </div>
        <div className="text-black-50">
          Items Count:
          <span className="font-medium mx-1">{items}</span>
        </div>
      </div>

      <Table
        ref={tableRef}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        bordered
        loading={loading}
        size="small"
        scroll={{ x: 'max-content' }}
      />
    </div>
  );
};

export default CapacityTable;
